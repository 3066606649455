@font-face {
  font-family: "GARABD";
  src: url("../../fonts/GARABD.eot");
  src: url("../../fonts/GARABD.eot") format("embedded-opentype"),
    url("../../fonts/GARABD.woff2") format("woff2"),
    url("../../fonts/GARABD.woff") format("woff"),
    url("../../fonts/GARABD.ttf") format("truetype"),
    url("../../fonts/GARABD.svg#GARABD") format("svg");
}

@font-face {
  font-family: "GREATVIBESREGULAR";
  src: url("../../fonts/GREATVIBESREGULAR.eot");
  src: url("../../fonts/GREATVIBESREGULAR.eot") format("embedded-opentype"),
    url("../../fonts/GREATVIBESREGULAR.woff2") format("woff2"),
    url("../../fonts/GREATVIBESREGULAR.woff") format("woff"),
    url("../../fonts/GREATVIBESREGULAR.ttf") format("truetype"),
    url("../../fonts/GREATVIBESREGULAR.svg#GREATVIBESREGULAR") format("svg");
}

@font-face {
  font-family: 'OSWALDREGULAR';
  src: url('../../fonts/OSWALDREGULAR.eot');
  src: url('../../fonts/OSWALDREGULAR.eot') format('embedded-opentype'),
    url('../../fonts/OSWALDREGULAR.woff2') format('woff2'),
    url('../../fonts/OSWALDREGULAR.woff') format('woff'),
    url('../../fonts/OSWALDREGULAR.ttf') format('truetype'),
    url('../../fonts/OSWALDREGULAR.svg#OSWALDREGULAR') format('svg');
}

body {
  /*background-image: url("../../web_img/background_bg.jpg");*/
  background: #eee;
}



.admin_stl {
  background: blue;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  border: 1px solid blue;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
}

.eclipse_btn {
  margin: 0px;
  width: 100%;
  display: flex;
  color: #000 !important;
  font-weight: 400 !important;
}

.user_profile_image {
  border-radius: 50%;
}

.follow_m_box {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.post_file_viewer video,
.post_file_viewer audio,
.post_file_viewer img {
  width: 100%;
}

.org_post_box {
  border-radius: 5px;
  outline: 1px solid #0005;
}

.fol_status {
  color: green;
  outline: 1px solid #0003;
  /* background-color: rgb(235, 233, 233); */
  /* padding: 5px 15px 0 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  gap: 5px;
  border-radius: 5px;
}

.member_container {
  /* background-color: rgba(184, 179, 179, 0.26); */
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  outline: 1px solid #0005;
}

.post_status {
  padding: 5px;
  text-transform: capitalize;
  border-radius: 5px;
}

.post-creator {
  position: relative;
}

.org_mark {
  position: absolute;
  padding: 0px 7px;
  outline: 1px solid green;
  color: green;
  right: 10px;
  top: 10px;
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
}

.org_mark,
.post_marks,
.post_status.pending {
  color: green;
  outline: 1px solid green;
  white-space: nowrap;
}

.post_marks {
  /* position: absolute; */
  padding: 0px 7px;
  outline: 1px solid green;
  color: green;
  right: 10px;
  top: 5px;
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
  display: inline-block;
}

.post_status.pending {
  outline: 1px solid green;
  color: green;
}

.post_status.rejected {
  outline: 1px solid red;
  color: red;
}

.popup_like_section .btn.active {
  background-color: #d2f4a8;
}

.bg_allp {
  /*background-color: #e4e6eb;*/
  /*background-image: url("../../web_img/background_bg.jpg");*/
  background-color: #f1f1f1;
}

.bg_2 {
  background-color: #a0c2d1 !important;
}

.bg_allp2 {
  background-color: rgb(252, 250, 250);
  background-color: #f1f1f1;
}

.minw_no {
  min-width: 60px;
  /* background-color: red; */
}

/* f5f5f5 */
#refAcc {
  outline: 1px solid #0003;
}

#refAcc .accordion-item {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #0001;
  box-shadow: none;
}

.editor p {
  font-size: 16px !important;
  line-height: normal !important;
}

#refAcc thead tr {
  background-color: #6c757d;
  color: #fff;
}

#refAcc .accordion-header {
  border-bottom: 0 !important;
}

#refAcc .accordion-body {
  background-color: #fff !important;
  border-bottom: none;
  padding: 0;
}

.overflow_man {
  overflow-y: scroll !important;
  height: 100% !important;
  max-height: 85vh;
}

.minw_t {
  min-width: 60px;
  padding: 0 15px;
  width: 50%;
}

.bg_allp3 {
  background-color: rgba(248, 248, 248, 0.692);
}

.admin_login {
  min-height: 100vh;
}

.admin_log_box {
  /* display: flex; */
  width: 100%;
  padding: 40px;
  margin-top: 35%;
  border-radius: 5px;
}

.shadow_1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/* .mhh_1{
	min-height: 500px;
} */

.max_width {
  max-width: 250px;
}

.items_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_post {
  height: 100px;
  font-size: 17px;
  color: gray;
  font-weight: 600;
}

.err-msg {
  background-color: rgb(233, 229, 229);
  margin-top: 10px;
  color: red;
  font-weight: bold;
  padding: 10px;
  width: 90%;
  font-size: 14px;
}

.bg-red {
  background-color: red !important;
}

.navbar {
  height: 80px;
  background-color: #0071be;
}

.left-sec-1 {
  padding: 15px;
}

.left-sec-con {
  padding: 5px 0;
}

#createPost {
  background-color: hsla(0, 0%, 100%);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 40px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 0px;
}

.middle-sec-box {
  width: 100%;
  display: flex;
  align-items: center;
  /* border-top-right-radius: 18px; */
}

.flex_po {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w_by_2 {
  width: calc(100% / 2 - 5px);
}

.br_5 {
  border-radius: 0px;
  overflow: hidden;
}

.o-hid {
  overflow: hidden;
  /* overflow-x: scroll; */
}

.video_intro iframe {
  width: 100% !important;
}

.profile-photo2 {
  border-radius: 50%;
}

.cover-photo .profile-photo2 {
  min-width: 150px;
  /* min-height: 240px; */
}

.post-area {
  padding: 15px 15px 0 15px;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid #0003;

  /* background-color: #F3E7E7; */
}

.create_post_img {
  width: 100%;
  position: relative;
}

.create_post_img img {
  width: calc(100% - 20px);
  margin: 10px;
}

.cross {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 15px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  cursor: pointer;
  z-index: 2;
}

.cross:hover {
  color: red;
}

.cancle_post {
  cursor: pointer;
  margin-top: 3px;
}

.po_text {
  flex-grow: 1;
}

.post_form {
  /* background-color: blue; */
  width: 100%;
}

.cover-photo {
  height: 35vh;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 5px;
  background-repeat: no-repeat;
}

/* userprofile  */
.profile_cover {
  position: relative;
  padding: 0;
  border: none;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 400px;
  overflow: hidden;
}

.form_preview_img img {
  min-width: 650px !important;
}

.profile_cover img {
  background-size: contain;
  max-height: 70vh;
}

.gall .col-lg-3 {
  width: 25% !important;
}

/*.admin_mark{
  background-color: #0001;
  width: 100%;
  padding: 10px;
}
*/

.gall_hover img {
  width: 100%;
  border-radius: 10px !important;
}

.photos_books a:hover {
  opacity: 0.7;
}

.gall_hover:hover {
  opacity: 0.7;
}

.profile_cover {
  height: 48vh;
  border-radius: 0;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  background-color: rgb(143, 135, 135);
  display: grid;
  place-items: center;
  /* background-repeat: no-repeat;
	background-position: center center;
	background-size: cover; */
}

/* profile info  */
.user_profile_info {
  padding: 15px;
  width: 100%;
}

/* end profile info  */

.cover-photo .profile-photo2 img {
  border-radius: 50%;
  width: 110px !important;
  height: 108px;
}

.profile-sec2 img {
  width: 120px;
  height: 120px;
}

.profile-photo {
  position: absolute !important;
  right: -50px;
  top: -15px;
}

.profile-photo2 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile .post-text {
  height: 120px;
  width: 100%;
  margin-left: 20px;
}

.pro_img_com img {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.cover-content {
  padding-left: 20px;
  width: 100%;
}

.comment-area {
  width: 100%;
  border-radius: 15px;
  padding: 5px 10px;
  height: max-content;
  background-color: rgb(247, 245, 245);
  border: none;
}

.comment-area:focus {
  outline: 1px solid #0003;
}

.post_action {
  display: flex;
  justify-content: end;
  gap: 5px;
  /* gap: 15px; */
}

.post_action button {
  padding: 3px 15px;
  font-size: 15px;
}

/* leftBar  */
.online-title {
  border-bottom: 1px solid #0003;
  display: flex;
  align-items: center;
  padding-bottom: 3px;
}

.left-bar-sec {
  background-color: ghostwhite;
}

.online-dot {
  height: 10px;
  width: 10px;
  background-color: #5dbb63;
  border-radius: 50%;
  margin-right: 10px;
}

.cover-sec {
  position: relative;
  background-color: rgb(143, 135, 135);
}

.profile_section {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 0px;
}

.profile_info_sec {
  padding: 0 2px 0 0;
  overflow-x: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profile_info_sec::-webkit-scrollbar {
  display: none;
}

.profile_info {
  padding: 15px 0;
  display: flex;
}

.profile_info img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.edit_pro {
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  bottom: 17px;
  /* left: 27px; */
  color: rgb(21, 162, 218);
  text-shadow: 0px 0px 6px white;
  padding: 2px;
  right: 3px;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  /* height: 30px; */
}

.edit_pro svg {
  width: 20px;
  color: #000;
}

.edit_pro:hover {
  text-decoration: none;
  background: #fff;
  padding: 2px;
  color: #0071be;
  border-radius: 12px;
  text-shadow: none;
}

.profile-sec2 {
  position: relative;
}

.profile-btn {
  position: absolute;
  left: 50px;
  bottom: -16px;
  background: transparent;
  border: 0;
  /* z-index: 99999999; */
  color: white;
  font-size: 18px;
}

.online-users {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.online-Profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* margin-right: 15px; */
}

.online-Profile img {
  border-radius: 50%;
}

.trend-tag svg {
  color: gray;
}

.trends-tag-1 svg {
  color: rgb(6, 122, 6);
}

.online-component a,
.left-sec-con a {
  color: #000;
  transition: 0.6s;
}

.left-sec-con a:hover {
  text-decoration: underline;
  transition: 0.6s;
}

.online-component a:hover {
  background: #c4c4c4;
  color: #000;
}

.home_back_img {
  /* background-image: url('../../web_img/fea-7.png'); */
  background-image: url("https://static.vecteezy.com/system/resources/previews/003/577/919/large_2x/floral-seamless-pattern-flower-background-floral-seamless-texture-with-flowers-flourish-tiled-white-spring-wallpaper-vector.jpg");
  background-size: 12% 100%;
  background-repeat: repeat-x;
}

.trend-video {
  max-width: 140px;
  min-width: 130px;
  width: 100%;
  background: #ffffff;
}

.video-box {
  width: 160px;
  height: 120px;
  background-color: black;
}

.trend-video iframe {
  height: 100%;
  width: 100%;
}

.trend-video img {
  max-width: 180px;
  width: 100%;
  height: 130px;
}

.trend-tags {
  display: flex;
  gap: 10px;
}

.trend-tag {
  background: none;
  padding: 6px 15px;
  border-radius: 0px;
  font-size: 12px;
  font-weight: bold;
}

.trend-title {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: #000;
  /*background: #c7c7c7;*/
  padding: 10px 10px 0px 10px;
  /* border-bottom: 1px solid #ccc; */
}

.ads {
  width: 100%;
  height: 120px;
  padding: 10px;
  background-color: hsla(0, 0%, 100%);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 0px 0 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

a {
  text-decoration: none;
}

.view-post {
  background-color: hsla(0, 0%, 100%);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 40px 0 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  border-radius: 0px;
  padding: 0px 15px;
}

.post_file_viewer.post_video_img_audio {
  text-align: center;
}

.post_video_img_audio audio,
.post_video_img_audio img,
.post_video_img_audio video {
  width: auto !important;
  max-width: 100% !important;
}

.post_file_viewer.post_video_img_audio img {
  width: auto !important;
  max-width: 100% !important;
}

.post_file_viewer.post_video_img_audio {
  text-align: center;
}

.post_video_img_audio audio,
.post_video_img_audio img,
.post_video_img_audio video {
  width: auto !important;
}

.post_file_viewer.post_video_img_audio img {
  width: auto !important;
}

.post-creator {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #0001;
}

.fs-22 {
  font-size: 22px !important;
}

.posted-user {
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  /* padding-left: 10px; */
  /* margin-bottom: 18px; */
}

.click_here {
  padding: 0 10px;
  font-weight: 800;
}

.click_here:hover {
  text-decoration: underline;
}

.posted-user-d {
  display: flex;
}

.posted-profile {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
}

.post-user-name {
  width: max-content;
}

.posted-user:hover {
  text-decoration: underline;
  color: #000;
}

.view-post-img {
  max-height: 320px;
  overflow: hidden;
}

.view-post-des {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fs_13 {
  font-size: 13px !important;
}

.view-post-img img {
  max-width: 100%;
  background-size: contain;
}

.logo-top img {
  width: 200px;
  height: 75px;
}

.bg-1 {
  background-color: #7dc1e7;
}

.bg-2 {
  background-color: #c6cf61;
}

.bg-3 {
  background: #cb58ab;
}

.px_15 {
  padding: 0 15px;
}

.post-action-sec {
  padding: 0 15px;
}

.items-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.fb_profile {
  background-color: red;
  max-width: 60px;
  font-size: 12px;
}

.banner_content h1 {
  font-size: 62px;
  font-weight: 600;
  color: #fff;
}

.banner_content h1 span {
  color: #99ca3c;
}

.banner_content h2 {
  font-family: "Open Sans", sans-serif;
  /* font-weight: 400;
    letter-spacing: 0em;
    text-align: left; */
  color: #fff;
}

.banner_content h2 span {
  display: block;
  color: #fff;
}

.cl_w {
  color: #fff;
}

/* .content h1{
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
} */
/* .content h4{
    font-style: italic;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
} */

/* @media only screen and (min-width: 1200px) {
    .banner_content h2{
        width: 90%;
    }
} */

.sh_on_m_500,
.sh_on_ma_500 {
  display: none;
}

.fit-100 {
  background-size: 100% 100% !important;
}

.mobile_login {
  display: none;
}

.my_space_img-col {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.my_space_img-col a.my-space_img_box {
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: block;
}

.my_space_img_hover {
  background-color: hsla(0, 0%, 100%, .8);
  left: -15px;
  opacity: 0;
  position: absolute;
  top: 10px;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  z-index: 9;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

.my_space_col:hover .my_space_img_hover {
  opacity: 1;
  position: absolute;
  left: 5px;
}

.my_space_img_hover2 {
  opacity: 0;
  position: absolute;
  right: -15px;
  top: 10px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.9);
}

.my_space_img_hover3 {
  top: 62px;
}

.my_space_col:hover .my_space_img_hover2 {
  opacity: 1;
  position: absolute;
  right: 5px;
}

.my_space_img_hover a {
  display: block;
  width: 32px;
  height: 32px;
  background-color: #99ca3c;
  line-height: 40px;
}

.my_space_img_hover a.member_hover {
  display: block;
  width: 130px;
  height: auto;
  background-color: transparent;
  line-height: 30px;
  padding: 0 10px 0 10px;
  cursor: pointer;
}

.my_space_img_hover a.member_hover span {
  width: 30px;
  height: 30px;
  background: #99ca3c;
  color: #000;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.my_space_img_hover .member_col.member_hover {
  display: block;
  width: 130px;
  height: auto;
  background-color: transparent;
  line-height: 30px;
  padding: 0 10px 0 10px;
  cursor: pointer;
}

.my_space_img_hover .member_col.member_hover span {
  width: 30px;
  height: 30px;
  background: #99ca3c;
  color: #000;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-left: 10px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.my_space_img_hover .div_link {
  display: block;
  width: 32px;
  height: 32px;
  background-color: #99ca3c;
  line-height: 40px;
}

.my_space_links {
  top: 20px;
}

.my_space_links a {
  display: inline-block;
  vertical-align: text-top;
  cursor: pointer;
}

.my_space_links .myspace_toplink {
  display: inline-block;
  vertical-align: text-top;
  cursor: pointer;
}

.lang_box {
  border: 1px solid #0002;
  padding: 2px 7px;
  margin-right: 5px;
  border-radius: 3px;
  background-color: #0002;
  color: #000;

}

.my_space_nav {
  background-color: #99ca3c;
  line-height: 32px;
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.my_space_nav .material-symbols-outlined,
.my_space_img_hover .material-symbols-outlined {
  font-size: 20px;
}

.my_space_links .btn {
  border: none;
  padding: 0;
}

.my_space_links .dropdown-toggle::before,
.my_space_links .dropdown-toggle::after,
.my_space_links .dropdown::before {
  border: none;
}

.pos_r {
  position: relative !important;
}

.videoplay_hoverx {
  display: none;
}

.you_h:hover .videoplay_hoverx {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  background-color: #0002;
  transition: 0.6s;
}

.videoplay_hoverx span {
  color: #fff;
  height: 48px;
  width: 70px;
  background-color: red;
  display: grid;
  place-content: center;
  border-radius: 15px;
}

.my_space_links .btn-success,
.my_space_links .btn-success:focus,
.my_space_links .btn-success:hover {
  background-color: #99ca3c;
  height: auto;
  background: none;
}

.popup_newmyspace {
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.popup_newmyspace::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.popup_in {
  position: absolute;
  background-color: #fff;
  width: 80%;
  top: 3px;
  margin: auto;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
  left: 0;
  right: 0;
  height: 99%;
  padding-bottom: 15px;
}

.popup_in .close_section {
  position: absolute;
  top: 0;
  left: 0;
}

.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.popup_create_post textarea {
  height: 300px;
}

.my_space_img_hover.friends_hover {
  background-color: #fff;
}

.my_space_img_hover.friends_hover a {
  width: auto;
  height: auto;
  line-height: inherit;
}

.videoplay_hover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: auto;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.videoplay_hover span {
  color: #fff;
  height: 50px;
  width: 50px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.videoplay_hover span.material-symbols-outlined {
  font-size: 55px;
}

.my-space_vieos_box:hover .videoplay_hover {
  opacity: 1;
  height: 100%;
  width: 100z5;
}

.my_space_img-col pre {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  /* number of lines to show */
  line-clamp: 10;
  -webkit-box-orient: vertical;
}

.video_thumbnail {
  height: 220px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: block;
}


@media(max-width: 640px) {
  .banner_img {
    height: 205px;
  }

  .your_work_position {
    margin-top: -20px;
  }

  .your_work_position_height {
    height: auto;
    padding: 15px 0px !important;
  }

  .some_member_post_img li {
    width: 37.17% !important;
    height: 113px;
    padding: 10px !important;
  }

  .your_work {
    line-height: 25px;
    padding-bottom: 10px !important;
  }

  .btn {
    font-size: 16px;
  }

  .some_member_post_img.home_post_new li {
    float: left;
    display: inherit;
  }

  .some_member_post_img.home_post_new li {
    width: 38.17% !important;
    padding: 0px !important;
  }

  .background_opacity {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 15px !important;
  }
}

@media only screen and (max-width: 600px) {
  .sh_on_m_500 {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .sh_on_ma_500 {
    display: block;
  }
}

.pro_info_tag {
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  color: #000;
  width: max-content;
}

.opt_more_right {
  display: grid;
  place-items: center;
}

.opt_more {
  display: grid;
  place-items: center;
  background-color: rgb(228, 227, 227);
  font-weight: 700;
  font-size: 18px;
  border-radius: 0px;
  padding: 8px 15px;
  cursor: pointer;
}

.opt_more:hover {
  background-color: rgb(209, 205, 205);
}

.profile_pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  background-color: #000;
  position: relative;
  margin-top: -60px;
  border: 2px solid #fff;
}

.profile_pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
}

.profile_pic a {
  background: #3f5e03;
  padding: 5px;
  position: absolute;
  top: 87px;
  right: -15px;
}

.holder_name {
  position: absolute;
  top: 65px;
  left: 192px;
  font-size: 22px;
  /* font-family: "Open Sans", sans-serif; */
  font-weight: bold;
}

.post_section textarea {
  width: 100%;
  display: block;
  resize: none;
  height: 102px;
  border: none;
  padding: 15px 0px 0px 15px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  margin-bottom: 30px;
}

.post_section textarea:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}

.post_section {
  margin-top: 20px;
}

.post_section input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0px;
  background: #3f5e03;
  color: #fff;
  border: 0px;
  padding: 6px 10px;
}

.post_box {
  padding: 10px;
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  margin-bottom: 30px;
}

.post_box {
  position: relative;
}

.post_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  overflow: hidden;
}

.post_image img {
  max-width: 100%;
  max-height: 100%;
}

.post_image h1 {
  position: absolute;
  top: 18px;
  left: 60px;
  margin: 0;
  font-size: 20px;
}

.post-image-box {
  position: relative;
}

.post-image-box img {
  max-width: 100%;
}

.post_like_box {
  position: absolute;
  right: -8px;
  background: #fcfcfc;
  padding: 0px 2px;
  margin-top: -90px;
  text-align: center;
  line-height: 0;
}

.post_like_box a {
  display: inline-block;
  background: #ebebeb;
  /* padding: 1px; */
  margin: 2px;
}

.post_like_box a svg {
  width: 22px;
}

.comment_bottom {
  padding: 4px 10px;
  text-align: right;
  margin-top: 0px;
  border-top: 1px solid #e7e7e7;
}

.comment_bottom a {
  display: inline-block;
  margin-left: 20px;
  color: #000;
  padding: 0 10px;
}

.comment_bottom a:hover {
  background: #f0f0f0;
  border-radius: 50px;
}

.comment_bottom a svg {
  margin-right: 5px;
  display: inline-block;
}

.comment_bottom svg {
  margin-right: 5px;
}

.post-image-box h2 {
  font-size: 20px;
}

.post-image-box p {
  font-size: 14px;
}

.view_recent_comment {
  display: block;
  text-align: right;
  color: #3f5e03;
  font-weight: bold;
}

.view_recent_comment:hover {
  color: #000;
}

.leave_comment {
  position: relative;
  border-top: 1px solid #ccc;
  padding: 15px;
  margin-top: 10px;
}

.leave_comment input[type="text"] {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  border: none;
  width: 82%;
  height: 40px;
  padding-left: 15px;
}

.leave_comment input[type="text"]:focus {
  outline: none !important;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}

.leave_comment input[type="submit"] {
  position: absolute;
  right: 0px;
  background: #3f5e03;
  color: #fff;
  border: 0px;
  padding: 6px 10px;
}

.post_like_box.no-img_box {
  margin-top: 34px;
}

.newandfeed {
  background: #fff;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  margin-top: 20px;
}

.newandfeed h1 {
  margin: 0;
  background: #2a2a2a;
  color: #fff;
  font-size: 25px;
  padding: 10px 15px;
}

.newandfeed li {
  list-style: none;
  background-image: url("../../web_img/arrow2.png");
  background-repeat: no-repeat;
  background-position: 0% 50%;
  background-size: 12px;
}

.newandfeed ul {
  margin: 0px;
  padding: 15px;
}

.newandfeed li a {
  color: #000;
  font-size: 18px;
  padding: 0 0 0 25px;
}

.newandfeed li a:hover {
  color: #3f5e03;
}

.add_box img {
  max-width: 100%;
}

.add_box h2 {
  font-size: 15px;
  text-align: center;
  background: #000;
  color: #fff;
  padding: 10px 5px;
}

.add_box h2 span {
  font-weight: bold;
}

.comment_top_nav {
  text-align: center;
  position: relative;
  display: block;
  margin-top: 0px;
}

.comment_top_nav_in {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  display: block;
  padding: 5px 15px;
  margin-bottom: 20px;
}

.comment_top_nav a {
  margin-left: 15px;
  color: #000;
}

.comment_top_nav a img {
  margin-right: 5px;
}

.holder_name {
  font-size: 28px;
}

.add_box {
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  padding: 15px;
  margin-bottom: 15px;
}

.post-image-box h2 {
  color: #7ab903;
  margin: 0px;
}

.post_top_navigation ul {
  margin: 0;
  padding: 0 10px 0 0;
  text-align: center;
  margin-bottom: 2px;
}

.post_top_navigation ul li {
  list-style: none;
  display: inline-block;
  margin-left: 2px;
}

.post_top_navigation ul:first-child {
  margin-top: 0px;
}

.post_top_navigation ul:last-child {
  margin-bottom: 15px;
}

.post_top_navigation ul li:first-child {
  margin-left: 0px;
}

.post_top_navigation ul li a {
  color: #000;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px 15px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
}

.post_top_navigation ul li a.select {
  background-color: #99ca3c;
  color: #fff;
}

.main-post_box {
  height: auto;
  overflow: hidden;
  text-align: center;
}

.main-post_box pre {
  text-align: left;
}

.post_top_navigation {
  padding: 15px 0 5px 0;
  margin-bottom: 0;
  /*background-color: rgba(255, 255, 255);
    box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
    -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
    -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);*/
}

.about_text h3 {
  font-size: 50px;
  font-weight: 600;
  color: #99ca3c;
  line-height: 45px;
  color: #99ca3c;
}

.about_text p {
  margin-bottom: 20px;
  text-align: center;
  font-size: 22px;
}

.about_text .about_more_text p {
  text-align: center;
}

.about_img img {
  width: 365px;
  padding: 0 5px;
  margin-bottom: 30px;
}

.about_img img:nth-child(2) {
  width: 253px;
}

.terms h6 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
}

.terms .cmn_box {
  margin-top: 20px;
}

.terms .cmn_box h1 {
  font-size: 1.75rem;
  color: #212529;
  font-weight: normal;
}

#contact_top {
  background-position: top center;
}

.contact_form form {
  display: flex;
}

.contact-form {
  margin-top: 30px;
}

.contact-form h2 {
  line-height: 18px;
}

.contact-form .form-control {
  background-color: hsla(0, 0%, 100%);
  margin: 0 0 0 0;
  resize: none;
  border-radius: 0px;
  border: 0;
  height: 35px;
  padding-left: 15px;
  background: #f3f3f3;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.form-group {
  margin-bottom: 15px;
}

.contact-form textarea.form-control {
  height: 100px;
  resize: none;
}

.contact-form .form-control:focus {
  color: #212529;
  border-color: #99ca3c;
  outline: 0;
  box-shadow: none;
}

.contact-form p {
  font-size: 18px;
}

.contact-form.contact_info {
  margin-top: 50px;
}

.contact_info h2 {
  margin-bottom: 30px;
}

.contact-form.contact_info h1 {
  font-size: 2rem;
}

.contact_info h3 {
  font-size: 18px;
}

.contact_info p,
.contact_info p a {
  font-size: 16px;
  color: #000;
}

.contact_info p svg,
.contact_info p a svg {
  vertical-align: top;
  fill: #99ca3c;
  display: inline-block;
  margin-top: 2px;
  margin-right: 5px;
}

.contact_info p svg {
  width: 20px !important;
}

.contact_info .cmn_box {
  margin-left: 15px;
}

/** faq_section **/
.faq_section h1 {
  text-align: center;
  margin: 50px 0 30px 0;
}

.faq_section {
  margin: 50px 0 0 0;
}

.accordion-item {
  border: 0px;
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  margin-bottom: 5px;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
  box-shadow: none;
  font-weight: bold;
}

.accordion-body li {
  font-size: 16px;
  margin-bottom: 10px;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-header {
  border-bottom: 1px solid #ccc;
}

.accordion-header:last-child {
  border-bottom: 0px;
}

.accordion-body {
  border-bottom: 1px solid #ccc;
  background: #f5f5f5;
}

/**.accordion-button:not(.collapsed)::after {
    background-image:none;
    transform: rotate(-180deg);
	position: absolute;
	content: "-";
	right: 0;
	font-size: 20px;
	font-weight: bold;
}**/
.accordion-button:not(.collapsed)::after {
  background-image: url("../../web_img/down_arrow.png");
}

.faq_section h3,
.faq_section h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 35px;
}

.accordion-item:first-of-type .accordion-button {
  background: #fff;
}

#top_search_heading {
  min-height: auto;
  background-position: top;
  padding-top: 20px;
  background-image: none !important;
  background: #eee;
}

#top_search_heading h2 {
  color: #99ca3c;
  font-size: 62px;
  font-weight: 600;
}

#top_search_heading h3,
#top_search_heading h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  line-height: 1.2;
  padding-bottom: 30px;
}

.about_text h2 {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  font-weight: normal !important;
}

.cmn_box_new {
  padding: 20px;
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  margin-bottom: 20px;
  height: 400px;
}

.sd_tex {
  font-weight: 650 !important;
}

.sd_box.active,
.sd_box.active .sd_tex {
  color: #067a06;
}

.advance_search {
  position: absolute;
  width: 145px;
  right: 210px;
  margin: auto;
  bottom: 0;
  height: 38px;
  top: 8px;
  margin: auto;
}

.creation_banner {
  position: relative;
  margin-bottom: 0;
  height: 45px;
}

.creation_banner .cmn_btn {
  background-color: #99ca3c;
  border: 1px solid #99ca3c;
  border-radius: 20px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.creation_banner .cmn_btn svg,
.search_left .bg_b svg,
.post_action .bg_b svg {
  margin-right: 5px;
  /* margin-top: -3px; */
  /* vertical-align: bottom; */
  fill: #000;
  float: left;
}

.creation_banner .bg_b,
.search_left .bg_b,
.post_action .bg_b {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #dadada;
}

.search_left .bg_b {
  position: absolute;
  right: 4px;
  left: auto;
  top: 7px;
  padding: 2px 15px;
}

.post_action .bg_b {
  background-color: #99ca3c !important;
  color: #fff !important;
  padding: 3px 10px;
}

.creation_banner .cmn_btn:hover {
  background-color: #99ca3c;
  color: #fff;
  border: 1px solid #99ca3c;
}

.flipbook {
  text-align: center;
}

.flipbook h2 {
  margin-top: 25px;
}

.stf__parent {
  overflow: hidden;
}

.privecy_header,
.privecy_footer {
  font-size: 22px;
  font-weight: 600;
  color: #000;
  line-height: 45px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.privecy_footer {
  margin-top: 0px;
}

.privacy_policy h2,
.terms h1 {
  font-size: 45px;
  font-weight: 600;
  color: #99ca3c;
  width: 70%;
  line-height: 45px;
}

.terms.privacy_policy p,
.terms.privacy_policy ul li,
.terms h6,
.terms p,
.information_col ul li {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: normal;
}

.desktop_none {
  margin-top: 0px;
}

.img-responsive {
  max-width: 100%;
}

.mobile_img {
  display: none;
}

.terms h3 {
  display: block;
  width: 100%;
}

.blog_main .pro_img,
.post-user-name {
  display: inline-block;
  vertical-align: middle;
}

.blog_main a {
  display: block;
  line-height: 18px;
  margin-top: 7px;
  color: #000;
  font-size: 20px;
}

.ot_1 {
  outline: 1px solid #0001;
}

.blog_like.active svg {
  color: red;
}

.blog_main a:hover {
  text-decoration: underline;
}

.blog_main span {
  font-size: 14px;
  color: #000;
}

.blog_content_box {
  border-top: 1px solid #ccc;
  display: block;
  width: 100%;
  padding-top: 18px;
  margin-top: 10px;
}

.cl_g {
  color: #69a004;
}

.blog_content_box h3 {
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 24px;
}

.blog_content_box img {
  max-width: 100%;
  margin-right: 20px;
}

.blog_title {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* background-color: red; */
  font-size: 1.25rem;
  white-space: nowrap;
}

.blog_content_box img {
  max-width: 100%;
  height: auto;
  max-height: auto;
}

.short_des {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  display: block;
  white-space: nowrap;
}

.blog_content_box2 img {
  max-height: inherit;
  max-width: 100%;
}

.blog_content_box2 .blog_icon {
  text-align: center;
}

.blog_content_box .image-style-side img {
  max-width: 35%;
  margin-right: 20px;
  float: left;
}

.blog_content_box .image-style-side.even img {
  float: right;
  margin-right: 0px;
  margin-left: 20px;
}

.float_left {
  float: left;
}

.float_right {
  float: right;
  margin-right: 0 !important;
  margin-left: 20px;
}

.preview_image img {
  max-height: 250px;
}

.blog_heading {
  color: #69a004;
  font-size: 40px;
  font-weight: 600;
  margin-top: 25px;
}

.blog_content_box p {
  font-size: 16px;
}

.blog_content_box a p,
.blog_content_box p {
  font-size: 16px;
  color: #000;
}

.blog_content_box p.blog_mgs {
  font-size: 18px;
  font-weight: bold;
}

.blog_main {
  position: relative;
}

.blog_main:before {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

/* .blog_main:hover:before {
	content: "";
	position: absolute;
	background-color: rgba(255, 255, 255, 0.2);
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
} */
.blog_content_box pre {
  max-width: 100%;
}

.blog_main form textarea {
  height: 100px;
  resize: none;
  border-radius: 0;
}

.blog_main .btn-primary {
  color: #fff;
  border: none;
  background: #000;
}

.profile_circle_lg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile_circle_lg img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.blog_main .mt-4 {
  margin-top: 0 !important;
}

.nav-main .btn-primary {
  background: #000;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 0.375rem 0.75rem;
  float: right;
  position: absolute;
  right: 0;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #99ca3c;
  box-shadow: 0 0 0 0.25rem rgba(174, 255, 44, 0.5);
}

.nav-main .px-3 {
  padding-right: 0;
  padding-left: 0;
}

.nav-main .bg-white {
  border-top: 1px solid #ededed;
  box-shadow: none;
  padding: 5px 0px !important;
}

.nav-main .notification_msg {
  font-weight: 600 !important;
  font-size: 15px;
}

.pro_img_com {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  padding: 5px 0;
}

.pro_img_com span {
  position: absolute;
  top: 3px;
  left: 40px;
}

.like_box_right {
  position: absolute;
  top: 6px;
  right: 5px;
}

.like_box_right svg {
  width: 18px;
  fill: #33b10a;
}

.masonry {
  /*margin: 1.5em 0;*/
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: 0.85em;
}

.masonry .item {
  display: inline-block;
  padding: 0;
  /* margin: 0 0 25px 0; */
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* -webkit-box-sizing: border-box; */
  /* box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.18); */
  /* overflow: initial; */
  overflow: hidden;
  margin-bottom: -10px;
}

.member_img_box {
  position: relative;
  width: 140px;
  height: 140px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 2;
  margin-bottom: 15px;
  margin-top: -50px;
}

.masonry .item .item_in {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  /*border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;*/
  overflow: initial;
}

.title,
.footer {
  text-align: center;
}

.title {
  font-size: 1.75em;
  margin: 0.25em 0;
}

.title a {
  display: inline-block;
  padding: 0.75em 1.25em;
  color: #888;
  border: 2px solid #aaa;
  margin: 0.25em 1em 1em;
  text-decoration: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.title {
  color: #666;
}

.title a:hover {
  color: #666;
  border-color: #888;
}

.share-link,
.article-link {
  color: #888;
}

.autographs_member .item img {
  max-width: 100%;
}

.autographs_member .item iframe {
  width: 100%;
}

.member_text_box h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.masonry.exchange_section .item {
  padding: 15px 0px 0 0;
}

.masonry.exchange_section .item .item_in {
  padding: 15px 0px 0 0px;
}

.masonry.exchange_section .item .member_text_box {
  padding: 0 20px;
}

.masonry.exchange_section .item_in h5 {
  background: #9a0101;
  padding: 10px 15px;
  color: #fff;
  font-size: 20px;
}

.masonry.exchange_section .item_in h5 strong {
  font-weight: normal;
  position: relative;
  margin-left: 15px;
  margin-bottom: 0px;
  display: block;
}

.masonry.exchange_section .item_in h5 a {
  color: #fff;
  font-size: 16px;
}

.popup_like_section svg {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -5px;
}

.popup_like_section .btn {
  background: #e7e7e7;
  border-radius: 0px;
  padding: 2px 10px;
}

.like_icon svg {
  width: 20px;
  fill: #067a06;
}

.like_box .pro_img {
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 0;
}

.like_box .pro_img:last-child {
  border-bottom: 0px;
}

.popup_like_section {
  margin-bottom: 15px;
}

/* .books_next_pre{

} */
.books_next_pre a {
  color: #000;
  background: #99ca3c;
  padding: 5px 8px;
  margin: 0 5px;
}

.books_next_pre a.zoom {
  color: #fff;
  background: #000;
  padding: 5px 8px;
  margin: 0 5px;
}

.books_next_pre a.zoom svg {
  fill: #fff;
  width: 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.advance_serch_img {
  height: 300px;
}

.advance_serch .hola {
  height: 300px !important;
  overflow: hidden;
}

.welcome_section_popup {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: auto;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  text-align: center;
}

.welcome_section_popup h2 {
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  padding-top: 20px;
}

.welcome_logo img {
  width: 200px;
}

.category_list .welcome_icon img {
  width: 70px;
}

.category_list .welcome_icon h3 {
  color: #000;
  text-transform: uppercase;
  font-size: 22px;
}

.category_list {
  background: #ffffff;
  padding: 0px 0 20px 0;
  margin-top: 15px;
}

.category_list h4 {
  background: #000;
  padding: 10px 20px;
  color: #fff;
  text-transform: uppercase;
}

.dance_section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.wecome_categori_border {
  margin-top: 40px;
}

.nav-tabs {
  width: 95%;
  margin: 0 auto;
  border-bottom: 0px;
  margin-bottom: 18px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  cursor: n-resize;
}

.nav-tabs .nav-link {
  color: #000;
  margin-right: 10px;
  font-weight: 600;
}

.nav-tabs .nav-link:hover {
  color: #000 !important;
  border-color: 0px;
  background-color: 0px;
  font-weight: 600;
}

.likes_count .c_pointer,
.likes_count .c_pointer div {
  background: #ccc;
  padding: 5px 10px;
}

.likes_count_btn {
  background: #ebebeb;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6 !important;
}

.modal-lg,
.modal-xl {
  max-width: 100%;
}

.modal {
  padding-left: 0 !important;
}

.modal-dialog {
  margin: 0;
}

.modal-body {
  padding: 50px 15px !important;
  width: 100%;
}

.close_section {
  position: absolute;
  left: 26px;
  top: 0;
  padding: 8px 15px;
  background: #f00;
}

.close_section svg {
  color: #ffffff;
}

.search_left .form-controll {
  margin-top: 10px;
  border: 0;
  background: #f5f5f5;
  border-radius: 0px;
  height: 35px;
  margin-bottom: 13px !important;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.search_left .form-controll:focus-visible {
  outline: none;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.latest_news {
  margin-bottom: 0px !important;
  padding-bottom: 0px;
}

.latest_news li {
  list-style: none;
  border-bottom: 1px solid #e6e6e6;
  /*height: 150px;*/
  padding: 10px 0;
}

.latest_news li a {
  color: #000;
}

.latest_news li a span {
  font-size: 14px;
  margin-top: 5px;
  display: block;
  text-align: left;
}

.news_img_box {
  text-align: center;
  position: relative;
}

.news_img_box img,
.news_img_box video,
.news_img_box iframe {
  max-height: 95%;
  width: auto;
  max-width: 95%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.latest_news li {
  /*height: 140px;*/
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 10px 0;
}

.latest_news a h2 {
  font-size: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.latest_news a p {
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.latest_news li a span svg {
  width: 20px;
  vertical-align: middle;
  margin-right: 2px;
  display: inline-block;
  margin-top: -5px;
}

.latest_news li a span {
  font-size: 14px;
  margin-top: 5px;
  display: block;
  text-align: left;
}

.form_modal {
  overflow: hidden;
}

.latest_news li a small {
  font-size: 15px;
  display: block;
  width: 100%;
  text-align: left;
  color: #659707;
}

.latest_news li a small {
  text-align: right;
}

.modal-lg,
.modal-xl {
  max-width: 100%;
}

.modal {
  padding-left: 0 !important;
  padding-right: 0px !important;
}

.modal-dialog {
  margin: 0;
}

.modal-body {
  padding: 50px 0px !important;
  width: 100%;
  height: 100% !important;
}

.posted-user-d {
  color: #000 !important;
}

/*.popup_image_section {
  padding: 50px 100px;
  background: #000;
  overflow: hidden;
  text-align: center;
  height: 550px;
  position: relative;

.popup_image_section,.single_post_modal .blog_h{
  padding: 50px 100px;
  background: #000;
  overflow: hidden;
  text-align: center;
  height: 90%;
  position: fixed;
  width: 66%; 
  top:0;
  bottom: 0;
  margin: auto;
}
}*/
.popup_image_section {
  background: #000;
  bottom: 0;
  height: 90%;
  margin: auto;
  overflow: hidden;
  padding: 50px 100px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 66%;
}

.popup_image_section img {
  width: auto;
  max-height: 95%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.popup_image_section video,
.popup_image_section audio,
.popup_image_section iframe {
  max-height: 95%;
  padding-bottom: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.close_section {
  position: absolute;
  left: 10px;
  top: 0;
  padding: 8px 15px;
  background: #f00;
}

.cl_rh,
.cl_g1 {
  color: #000;
}

.cl_hov_cng:hover .cl_g1 {
  color: #1b651c;
}

.cl_hov_cng:hover .cl_rh {
  color: red;
}

.close_section svg {
  color: #ffffff;
}

.search_left .form-controll {
  margin-top: 10px;
  border: 0;
  background: #f5f5f5;
  border-radius: 0px;
  height: 35px;
  margin-bottom: 13px !important;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.search_left .form-controll:focus-visible {
  outline: none;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.latest_news ul {
  margin-top: 0 !important;
  padding: 0 10px !important;
  height: 630px !important;
}

.latest_news .latest_news img {
  margin-right: 10px;
}

.latest_news li a {
  color: #000;
}

.news_img_box {
  background: #eaeaea;
  height: 110px;
  padding: 5px;
  overflow: hidden;
}

.home_login h3 {
  display: none;
}

.home_login .phone-tag {
  max-width: 100%;
}

.home_login .login-form label {
  display: none;
}

.home_login .login-form {
  margin-top: 0 !important;
  width: 49%;
  float: left;
  margin-right: 3px;
}

.home_login .mt-4,
.home_login .mt-2 {
  margin-top: 0rem !important;
  margin-bottom: 5px !important;
}

/* .home_login .mt-1 {} */

.Login .form-control {
  background: none !important;
  border: 0px;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0px;
  margin-top: 15px;
}

.home_login h2 {
  font-size: 20px;
  text-align: center;
  margin: 10px 0px;
  text-transform: uppercase;
}

.home_login {
  margin-top: 15px;
}

.home_login a {
  color: #000;
}

.home_login .clr-p:hover {
  color: rgb(93 187 99);
}

.preserve_legacy_section {
  margin-top: 90px;
}

.carousel-control-prev {
  left: 0px;
  width: 50px;
}

.carousel-control-next {
  right: 0px;
  width: 50px;
}

.book_list_right .col-sm,
.col-md {
  flex: 0 0;
}

.book_list_box {
  width: 18%;
  margin: 0 10px;
  margin-top: 20px;
  padding-top: 15px;
}

.book_list_left {
  margin-top: 20px;
}

.book_list_left,
.book_list_box {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
}

.booklist_content {
  text-align: center;
  line-height: 20px;
  padding-bottom: 10px;
}

.booklist_content h2 {
  font-size: 15px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booklist_content span {
  margin: 0;
  font-size: 12px;
  background: #e9e9e9;
  display: block;
  margin-top: 2px;
}

.booklist_content p {
  margin: 0;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booklist_content span svg {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}

.booklist_img {
  margin-bottom: 5px;
}

.booklist_img {
  height: 240px;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.booklist_img img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
}

.view_book {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  top: -50px;
}

.booklist_img:hover .view_book {
  opacity: 1;
  top: 0px;
}

.view_book span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 26px;
  background: #9ac93f;
}

.book_list_left img {
  max-width: 100%;
}

.footer-end .f-end a {
  padding: 0 15px;
  border-right: 1px solid #000;
  word-break: break-all;
}

.creation_banner {
  padding-top: 10px;
  position: relative;
}

.creation_banner h1 {
  font-size: 25px;
  margin-top: 0px;
}

.post_video_img_audio video,
.post_video_img_audio img,
.post_video_img_audio audio {
  max-height: 700px !important;
  max-width: 100%;
}

.modal-open {
  margin-right: 0px !important;
  padding-right: 0px !important;
}

body.modal {
  padding-right: 0px !important;
}

.le_post_modal.modal.show {
  padding-right: 0px !important;
}

body.modal-open {
  padding-right: 0px !important;
}

.popup_desc pre {
  color: #fff;
}

.home_login h4 {
  text-align: center;
}

.win_close_btn {
  cursor: pointer;
}

/** Win Popup **/
.win_popup_bg {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow-y: scroll;
}

.win-popup_in {
  width: 900px;
  height: 600px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
}

.win_popup_heading {
  background-color: #fff;
  color: #000;
  text-align: center;
  font-size: 40px;
  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 8%), 0 -1px 3px 0 rgb(0 0 0 / 6%);
  padding: 2px 8px 15px 2px;
  position: relative;
  font-weight: bold;
  color: #c70b40;
}

.win_close_btn {
  position: absolute;
  right: 0;
  top: 0;
  top: 0px;
  right: 10px;
}

.win_close_btn img {
  width: 22px;
  height: 22px;
}

.win_list_blog {
  margin: 0;
  padding: 0;
  text-align: center;
}

.win_list_blog li {
  display: inline-block;
  list-style: none;
  vertical-align: top;
  margin-top: 20px;
  position: relative;
}

.win_list_blog li:nth-child(1),
.win_list_blog li:nth-child(3) {
  width: 264px;
  margin-top: 90px;
}

.win_list_blog li:nth-child(1) {
  margin-right: -20px;
}

.win_list_blog li:nth-child(3) {
  margin-left: -20px;
}

.win_list_blog li:nth-child(2) {
  width: 355px;
}

.win_list_blog li h2 {
  position: absolute;
  top: 52px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  left: 0px;
  right: 0;
  margin: auto;
}

.win_list_blog li:nth-child(2) h2 {
  top: 70px;
}

.wnr_profile_img {
  position: absolute;
  top: 100px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100px;
  -webkit-radius: 100px;
  -ms-radius: 100px;
  background-size: cover;
}

.wnr_profile_img img {
  width: 120px;
}

.win_list_blog li:nth-child(2) .wnr_profile_img {
  top: 140px;
}

/** create page **/
.create_page_main {
  background-color: #eff1f4;
}

.create_left {
  min-height: 800px;
  background-color: #fff;
  text-align: center;
  padding-top: 100px;
}

.create_logo {
  max-width: 300px;
  margin-bottom: 50px;
}

.create_left h2 {
  font-size: 18px;
}

.create_left h2 span {
  display: block;
  color: #609202;
  font-size: 35px;
}

.create_left p {
  font-size: 18px;
  padding: 0px 50px;
}

.create_right {
  padding: 50px 0px;
  padding-left: 50px;
}

.create_right input.form-control,
select.form-control {
  /* height: 60px;*/
  border-radius: 15px;
  margin-bottom: 15px;
}

.upload_profile {
  background: #fff;
  border-radius: 15px;
  -webkit-animation: 15px;
  -ms-border-radius: 15px;
  width: 300px;
  margin-top: 50px;
}

.upload_profile img {
  vertical-align: top;
  display: inline-block;
}

.upload_profile .upload_btns {
  color: #000;
  display: block;
  padding: 10px 10px;
  overflow: hidden;
  transition: 0.6s ease-out;
}

video {
  max-width: 100% !important;
}

.upload_profile .upload_btns:hover {
  transition: 0.6s ease-in;
  background-color: rgba(128, 128, 128, 0.349);
  border-radius: 10px;
}

.create_upload_img {
  width: 150px;
  height: 150px;
  background: #ccc;
  border-radius: 50%;
  -web-border-radius: 50%;
  -ms-border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

#blog_details img {
  max-width: 100% !important;
}

.create_right textarea.form-control {
  height: 100px;
  resize: none;
  margin-top: 20px;
  border-radius: 15px;
  -webkit-animation: 15px;
  -ms-border-radius: 15px;
}

.t_left {
  text-align: start !important;
}

.upload_cover_pic {}

.upload_cover_pic .upload_profile {
  margin-top: 15px;
}

.upload_cover_pic {
  background: #ccc;
  border-radius: 15px;
  -webkit-animation: 15px;
  -ms-border-radius: 15px;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
}

.upload_cover_pic.upload_your_institution_photo {
  background: none;
}

.upload_your_institution_photo .upload_cover_pic {
  width: 300px;
  height: 200px;
  margin: 0 auto;
}

.institution_specifications {
  margin-top: 80px;
}

.upload_cover_pic.upload_your_institution_photo .upload_profile {
  margin: 20px auto;
}

.create_page_button {
  text-align: center;
  margin-top: 30px;
}

.create_page_button a {
  background-color: #000;
  color: #fff;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 15px;
  -webkit-animation: 15px;
  -ms-border-radius: 15px;
}

.create_right h1 {
  font-size: 20px;
  margin-top: 30px;
}

.share_post_preview video {
  width: 100% !important;
}

/** organization_post_left **/

.organization_post_left a {
  margin-right: 20px;
  background: #d7d7d7;
  padding: 5px 10px;
  border-radius: 10px;
}

.organization_post_left a:hover {
  color: #000;
  background: #b9b9b9;
}

.organization_post_left a:hover,
.organization_post_left a.active {
  color: #000;
  background: #b9b9b9;
}

.organiza_follow_us a {
  background-color: #cac8c8;
  padding: 10px 20px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.organiza_follow_us img {
  width: 110px;
}

.organiza_follow_us a:hover,
.organiza_follow_us a.active {
  background-color: #609202;
}

.index-header.home {
  background-color: rgba(0, 0, 0, 0.4) !important;
  position: fixed;
  top: 0;
  width: 100%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  background-size: 30px;
}

.kep-login-facebook.g_login {
  background-color: #f70000;
  border: calc(0.06887vw + 0.67769px) solid #f70000;
  border-radius: 0;
  margin-top: 10px;
}

.kep-login-facebook {
  padding: 6px 15px !important;
  font-size: 15px !important;
}

.order-3 {
  background-color: #000;
  color: #fff;
  padding: 5px 0px;
}

.order-3 a {
  color: #fff;
}

.order-3 a:hover {
  color: #fff;
}

.footer-end {
  margin-bottom: 0px !important;
  background-color: #e2e2e2;
  padding-top: 15px !important;
}

.f-logo {
  margin-bottom: 15px;
}

.videos_new {
  background-color: #ccc;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 10px;
  -mox-border-radius: 10px;
}

.cmn-new_btn a,
button.cmn-new_btn {
  background: #99ca3c;
  color: #fff;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: bold;
}

button.cmn-new_btn {
  padding: 5px 15px;
}

button.cmn-new_btn img {
  margin-top: 0;
}

.cmn-new_btn img {
  width: 31px !important;
  margin-left: 11px;
  vertical-align: middle;
  display: inline-block;
  margin-top: -5px;
}

.new_about_text p {
  line-height: 26px;
  margin-bottom: 35px;
}

.client_testimonial h1 {
  font-size: 2rem;
  padding: 0;
  margin: 0 0 5px 0;
}

.client_testimonial img {
  width: 30px;
  margin-bottom: 0;
}

.new_home p {
  line-height: 20px;
  font-size: 18px;
}

.client_profile_img {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #fff;
}

.client_name {
  position: absolute;
  left: 55px;
  top: 12px;
  width: 200px;
}

.client_name p {
  line-height: 0;
  padding: 0;
  margin: 0px 0 5px 0;
}

.review_descrip {
  height: 65px;
  font-size: 16px !important;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 0px;
}

.our_client .carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  background-size: 18px;
  height: 35px;
}

.our_client .carousel-control-prev,
.our_client .carousel-control-next {
  width: 35px !important;
  top: 0;
  height: 60px;
}

.our_client .carousel-control-prev {
  left: auto;
  width: revert;
  right: 60px;
}

.our_client .carousel-control-next {
  left: auto;
  width: revert;
  right: 20px;
}

.client_testimonial {
  background: #e2e2e2;
  padding: 10px;
  border-radius: 15px;
  height: 200px;
}

.carousel-indicators {
  display: none;
}

.new_tesrmonial .carousel-control-next,
.new_tesrmonial .carousel-control-prev {
  display: block !important;
  height: 48px;
  bottom: -40px;
  /* bottom: 0; */
  /* margin: auto; */
  top: auto;
  right: 20px;
}

.new_tesrmonial .carousel-control-prev {
  right: 60px;
  left: auto;
}

.event_comming .carousel-control-next-icon,
.carousel-control-prev-icon,
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: transparent !important;
  background-size: 20px !important;
  height: 3em !important;
}

.carousel-control-next-icon {
  background-image: url("../../web_img/slider_arrow.png");
}

.carousel-control-prev-icon {
  background-image: url("../../web_img/slider_arrow_2.png");
}

.our_client.new_banner_slider {
  margin-bottom: 25px;
  height: 300px;
  position: relative;
}

.our_client.new_banner_slider {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e3e3e3+100 */
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(left, #f1f1f1 0%, #e3e3e3 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #f1f1f1 0%, #e3e3e3 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #f1f1f1 0%, #e3e3e3 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#e3e3e3', GradientType=1);
  /* IE6-9 */
  border-radius: 0 15px 15px 0px;
}

.our_client.new_banner_slider .carousel-control-prev,
.our_client.new_banner_slider .carousel-control-next {
  top: 0px;
  height: 60px;
}

.new_banner_img {
  position: absolute;
  right: 50px;
  width: 345px;
}

.our_client.new_banner_slider .carousel-inner {
  overflow: inherit;
}

.our_client.new_banner_slider .cmn-new_btn {
  margin-top: 30px;
}

.new_banner_text {
  margin-top: 5px;
}

.banner_bottom_bar {
  height: 106px;
  background-repeat: no-repeat;
  background-position: right;
  width: 820px;
  position: absolute;
  bottom: 0;
}

.banner_bottom_bar:before {
  content: "";
  position: absolute;
  left: -18px;
  top: 0;
  width: 80%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,c6c4c4+24,c6c4c4+100 */
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(left, #f1f1f1 0%, #c6c4c4 24%, #c6c4c4 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left,
      #f1f1f1 0%,
      #c6c4c4 24%,
      #c6c4c4 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #f1f1f1 0%, #c6c4c4 24%, #c6c4c4 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#c6c4c4', GradientType=1);
  /* IE6-9 */

  height: 98%;
  bottom: 0;
  margin: auto;
  z-index: 0;
}

.new_home {
  padding: 15px 0;
}

.new_about_text {
  margin-top: 0px;
}

.over_text {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-top: 20px;
}

.over_text h2 {
  margin-bottom: 0px;
  font-weight: bold;
}

.login_or {
  width: 75px;
  height: 33px;
  background-color: #99ca3c;
  border-radius: 50px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 27px;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 50%);
}

.form-control,
.form-control:focus {
  border: none;
  border-bottom: 1px solid #a8a8a8 !important;
  border-radius: 0px;
}

.home_login .subscription_section {
  background-color: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 0px;
  background: none;
}

.home_login .subscription_section h1 {
  font-weight: normal;
  color: #000;
  width: 100%;
  line-height: 38px;
  font-size: 35px;
}

.home_login .subscription_section p {
  line-height: 16px;
}

.home_login .subscription_section li {
  font-size: 18px;
}

.blog_icon {
  width: 100%;
  height: 215px;
  text-align: center;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Login {
  background-image: url("../../web_img/login.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.home_login .subscription_section h1 {
  font-weight: normal;
  color: #000;
  width: 100%;
  line-height: 38px;
  font-size: 35px;
}

.home_login .subscription_section p {
  line-height: 16px;
}

.home_login .subscription_section li {
  font-size: 18px;
}

.blog_icon {
  width: 100%;
  height: 215px;
  text-align: center;
  overflow: hidden;
}

.Login {
  background-image: url("../../web_img/login.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.login_col_main {
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 20px 0 35px 0px;
  margin: 15px 0;
}

.cl_8 {
  background-color: rgba(255, 255, 255, 0.9);
}

.login_col_main .main_logo {
  max-width: 100%;
}

.login_col_main .main_logo img {
  width: 215px !important;
}

.login_col_main .login .mt-2 {
  margin-top: 0rem !important;
}

.registration_main .form-control {
  margin-top: 0;
}

.registration_main select.form-control {
  margin-bottom: 0px;
  height: 35px;
}

.registration_main .css-1s2u09g-control {
  border: 0;
  background: none;
  border-bottom: 1px solid #ccc;
}

#top_search_heading h1 {
  color: #000;
}

.index-header ul {
  list-style: none;
  margin-top: 10px;
}

.nav-main .p-2 {
  padding: 0 !important;
  padding-right: 10px !important;
}

#root main {
  background: #f1f1f1;
}

.box.forget_password {
  padding: 0 65px;
  text-align: center;
}

.forget_password .sub_head {
  width: 100%;
  margin: 0px 0px;
  /* background-color: red; */
  text-align: center;
  margin-top: 0;
  color: gray;
  font-size: 17px;
  line-height: 20px;
}

.login_col_main a {
  color: #000;
}

.Login {
  background-image: url("../../web_img/login.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.login_col_main {
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 20px 0 35px 0px;
  margin: 15px 0;
}

.login_col_main .main_logo {
  max-width: 100%;
}

.login_col_main .main_logo img {
  width: 215px !important;
}

.login_col_main .login .mt-2 {
  margin-top: 0rem !important;
}

.registration_main .form-control {
  margin-top: 0;
}

.registration_main select.form-control {
  margin-bottom: 0px;
  height: 35px;
}

.registration_main .css-1s2u09g-control {
  border: 0;
  background: none;
  border-bottom: 1px solid #ccc;
}

#top_search_heading h1 {
  color: #000;
}

.index-header ul {
  list-style: none;
  margin-top: 20px;
}

.nav-main .p-2 {
  padding: 0 !important;
  padding-right: 10px !important;
}

#root main {
  background: #f1f1f1;
}

.box.forget_password {
  padding: 0 65px;
  text-align: center;
}

.forget_password .sub_head {
  width: 100%;
  margin: 0px 0px;
  /* background-color: red; */
  text-align: center;
  margin-top: 0;
  color: gray;
  font-size: 17px;
  line-height: 20px;
}

.login_col_main a {
  color: #000;
}

/** competition_result_in **/
.win-popup_in.competition_result_in {
  width: 100%;
  background-image: none;
}

.win-popup_in.competition_result_in .win_close_btn {
  display: none;
}

.win-popup_in.competition_result_in .win_popup_heading {
  box-shadow: none;
}

.win-popup_in.competition_result_in .win_list_blog li h2 {
  left: 0;
  font-size: 16px;
  top: 47px;
}

.win-popup_in.competition_result_in .win_list_blog li span {
  position: absolute;
  top: 65px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  left: 0px;
  right: 0;
  margin: auto;
  font-size: 12px;
  font-weight: normal;
}

.win-popup_in.competition_result_in .win_list_blog li span svg {
  color: #fff;
  fill: #fff;
  width: 25px;
  size: 10px;
}

.container_for_competition_result.container {
  width: 100%;
  margin-bottom: 80px;
}

.win-popup_in.competition_result_in .win_list_blog li:nth-child(2) h2 {
  top: 60px;
}

.win-popup_in.competition_result_in .win_list_blog li:nth-child(2) span {
  top: 80px;
}

.container_for_competition_result.container .accordion-header button {
  font-size: 35px;
  color: #c80a40;
  font-weight: bold;
  text-align: center;
  display: block;
}

.container_for_competition_result.container .accordion-header .accordion-button:after {
  position: absolute;
  right: 15px;
  top: -6px;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  background-image: url("../../web_img/aco_arrow.png");
  background-size: 30px;
}

.container_for_competition_result.container .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("../../web_img/aco_arrow.png");
  position: absolute;
  right: 15px;
  top: -6px;
  bottom: 0;
  margin: auto;
  background-size: 30px;
  width: 30px;
  height: 30px;
}

.container_for_competition_result .accordion-body {
  padding: 0;
}

.container_for_competition_result .win-popup_in {
  width: 100%;
  height: auto;
}

.container_for_competition_result .win-popup_in {
  margin-top: 0;
}

.organisation_listing {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.organisation_listing .card {
  width: 50%;
  margin: 15px auto;
  overflow: hidden;
  padding: 15px;
}

.card>.list-group:last-child {
  border: none;
}

.user_info {
  position: relative;
}

.organisation_listing .card svg {
  margin-right: 3px;
  fill: #fff;
  margin-top: 2px;
}

.organisation_listing .list-group-item {
  padding: 10px 0;
}

.organisation_list .blog_icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.organisation_list .blog_heading {
  margin-top: 0px;
}

.organisation_list .form-control {
  border-radius: 15px;
}

.organisation_list .btn {
  padding: 0px 5px;
}

.organisation_list svg {
  float: left;
  margin-top: 3px;
}

.organisation_list .org_delete,
.organisation_list .org_delete svg,
.btn-org_delete svg {
  fill: #dc3545;
}

.btn-like svg,
.unfollow svg {
  fill: #0b5ed7;
}

.btn_follow .text-primary {
  background-color: #0d6efd !important;
  color: #fff !important;
  border: none !important;
}

.btn_follow svg {
  margin-top: 2px;
}

.btn_follow .btn-btn-light {
  background-color: #99ca3c !important;
  color: #fff !important;
}

.btn_follow .text-danger {
  background-color: #f70000 !important;
}

.btn_follow .bg-white svg {
  fill: #000 !important;
}

.btn_follow .bg-white.text-primary svg {
  fill: #fff !important;
}

.btn_follow .text-danger {
  color: #fff !important;
}

.top_videos {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
}

.video_section_popup {
  position: absolute;
  height: 420px;
  width: 80%;
  left: 0;
  right: 0;
  top: 15%;
  margin: auto;
}

.video_section_popup .bg-white {
  height: 300px;
}

.home_video {
  padding: 6px;
  height: 200px;
  cursor: pointer;
}

.home_video video {
  height: 180px !important;
  background-color: #000;
}

.home_video img {
  height: 184px !important;
  width: 100%;
}

.com_rules .carousel-control-prev,
.com_rules .carousel-control-next {
  display: none;
}

.home_popup .popup_image_section {
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  top: auto;
  bottom: 0;
  width: 100%;
}

.home_popup .close_section {
  left: auto;
  right: 10px;
  z-index: 999;
}

.home_popup .popup_hedign {
  display: block;
  width: 100%;
  position: absolute;
  top: 10px;
}

.home_video {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.home_video img {
  height: 184px !important;
  width: auto !important;
}

.home_video:hover:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.161);
}

.allphanes_provides.bg-white {
  border-radius: 15px;
}

.allphanes_provides li {
  position: relative;
  list-style: none;
  padding-left: 25px;
  margin: 0 15px;
}

.allphanes_provides li::before {
  background-image: url(../../web_img/arrow.png);
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 15px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 15px;
}

.img-responsive.small_img {
  max-width: 40px;
}

.december_winners {
  position: relative;
}

.december_winners .d-flex a {
  width: 34%;
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.december_winners .d-flex a div {
  height: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 8px solid #fff;
  margin: 5px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.december_winners .december_winners_img_box {
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
}

.winner_modal .modal-content {
  background-color: rgba(255, 255, 255, 0.9);
}

.wineer_popup {
  top: 17%;
}

.wineer_popup .wnr_profile_img {
  top: 80px;
}

.event_comming {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 270px;
  height: 230px;
  border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  background: #fff;
  z-index: 2;
}

.evtn_org_bg {
  width: 100%;
  height: 160px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.evtn_org_bg {
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border: 5px solid #0b5ed7;
  position: relative;
}

.event_comming h2 {
  background-color: #99ca3c;
  border-radius: 20px 20px 0 0;
  font-size: 20px;
}

.evtn_org_bg h3 {
  background-color: #99ca3c;
  position: absolute;
  bottom: -9px;
  left: 0;
  right: 0;
  border-radius: 0 0 15px 15px;
  -moz-border-radius: 0 0 15px 15px;
  -webkit-border-radius: 0 0 15px 15px;
  font-size: 14px;
  color: #000;
}

.event_comming .carousel-control-next-icon {
  background-size: 15px;
}

.event_comming .carousel-control-next-icon,
.carousel-control-prev-icon {
  display: block;
  width: 50px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
}

.event_comming_close {
  position: absolute;
  right: 0;
  top: 0;
  right: 5px;
  top: 2px;
}

.event_comming_close img {
  width: 20px;
}

.event_comming .carousel-control-next {
  right: 0px;
  left: auto;
  width: 30px;
  height: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.event_comming .carousel-control-prev {
  left: 0px;
  left: auto;
  width: 30px;
  height: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.event_comming .carousel-control-prev-icon {
  height: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ck.ck-editor {
  position: relative;
  width: 100% !important;
  height: auto;
}

#createPost .ck.ck-reset_all p {
  font-size: 14px;
}

.ck.ck-icon {
  font-size: 8px !important;
}

.event_back {
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.fill_success {
  fill: #8abf12;
}

.fill_white {
  fill: #fff;
}

.bg-success {
  background: #8abf12 !important;
}

.text-success {
  color: #8abf12 !important;
}

.border-success {
  border-color: #8abf12 !important;
}

.event_small_details {
  bottom: 0;
  width: 100%;
}

.event-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

/* .release_date::after {
  position: absolute;
  bottom: -5px;
  content: "";
  border-bottom: 2px solid #000;
  left: 0;
  right: 0;
  width: 100px;
  margin: auto;
} */

.plan_list {
  margin: 0;
  padding: 0;
}

.plan_list li {
  height: auto;
  border-radius: 25px;
  list-style: none;
  width: 45%;
  display: inline-block;
  vertical-align: top;
}

.plan_top_bg {
  width: 100%;
  margin: 0px auto 0 auto;
  line-height: 18px;
  border-radius: 25px 25px 0 0;
}

.plan_top_bg h4 {
  padding-top: 20px;
  line-height: 10px;
}

.plan_top_bg h5 {
  font-size: 35px;
  margin-bottom: 0;
  margin-top: 0px;
}

.plan_top_bg h1 {
  font-size: 60px;
  font-weight: bold;
}

.plan_list .plan_list_li li {
  width: 100%;
  text-align: left;
  height: auto;
  padding: 3px 22px !important;
  background: #f5f5f5;
  margin: 4px 0px;
}

.plan_list .plan_list_li li .plan_icon {
  margin-right: 5px;
  width: 11px;
  position: absolute;
  right: 10px;
}

.plan_list_li {
  min-height: 220px;
}

.plan_list .btn-success {
  font-size: 25px;
  font-weight: bold;
  padding: 0px 15px;
}

.premierposts_in {
  position: relative;
}

.premierposts_in ul.nav-tabs {
  position: absolute;
  display: inline-block;
  margin-top: -57px;
  left: 130px;
  width: 90%;
}

.premierposts_in ul.nav-tabs li {
  float: left;
  position: relative;
  border-left: 1px solid #000;
  padding: 0;
}

.premierposts_in ul.nav-tabs li:first-child {
  border: 0;
}

.overlay_expried {
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  bottom: 0;
  z-index: 9;
}

.expired_btn {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.analytics_list_li {
  width: 200px;
  height: 200px;
  border: 8px solid;
  border-radius: 100%;
  background-color: #fff;
  display: inline-block;
  list-style: none;
  margin: 10px 5px;
  vertical-align: top;
}

.analytics_number {
  margin: 0 auto;
}

.analytics_number h3 {
  line-height: 88px;
}

.analytics_number h3.last-child {
  line-height: 28px;
  padding-top: 15px;
}

.pie_chart_vlw {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.desktop_g-play img {
  width: 100px;
}

.view svg {
  fill: #99ca3c;
}

.delete svg {
  fill: #f00;
}

.advance_search_box .post_file_viewer {
  max-height: 192px;
  overflow: hidden;
}

.advance_search_box .post_file_viewer iframe {
  max-height: 192px;
}

.videos_box video {
  max-height: 330px !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #99ca3c;
}

.win_popup_heading_in h4 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.all_creation {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.catrgory_main {
  text-align: center;
  position: relative;
}

.catrgory_main li {
  width: 15%;
  margin: 20px;
  display: inline-block;
  vertical-align: top;
}

.cateory_boximg {
  width: 200px;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  position: relative;
}

.cateory_boximg h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 35px;
  position: absolute;
  bottom: 0;
  top: 0;
}

.creation_sub_category .cateory_boximg {
  width: 150px;
  height: 150px;
  padding-top: 60px;
  margin: 0 auto;
  position: relative;
}

.creation_sub_category li h4 {
  font-size: 16px;
}

.creation_sub_category {
  position: absolute;
  margin-top: 18px !important;
  width: 100%;
  left: 0;
}

.sub_creation_main {
  margin-bottom: 115px;
}

.organisation_list .btn {
  padding: 0px 0px;
}

.blog_content_box2 .gap-1 {
  padding: 0;
}

.ppv_images img {
  max-height: 400px;
  width: auto;
}

.ppv_videos_image video,
.ppv_videos_image image {
  max-width: 80% !important;
  height: auto !important;
  border: 20px solid #fff;
  margin-bottom: 15px;
}

.breadcrumb a {
  color: #000;
}

.breadcrumb a:hover {
  color: #659705;
}

.sub_cat_ppv .cateory_boximg {
  width: 120px;
  height: 120px;
}

.sub_cat_ppv {
  text-align: center;
  margin: 0;
  padding: 0;
}

.sub_cat_ppv li {
  display: inline-block;
  width: 160px;
  vertical-align: top;
  margin-top: 20px;
}

.sub_cat_ppv li a {
  color: #000;
}

.sub_cat_ppv li a h6 {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-height: 40px;
}

.creation_top li {
  display: inline-block;
  vertical-align: top;
}

.creation_top li {
  margin: 5px 8px;
}

.creation_top .form-check-input {
  display: inline-block;
  vertical-align: text-top;
}

.creation_top li a {
  background: rgb(251 251 251);
  padding: 5px 15px;
  border-radius: 15px;
  border: 1px solid #ccc;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  -mox-transition: all 1s ease-out;
}

.creation_top li a:hover {
  background: #99ca3c;
  border: 1px solid #99ca3c;
  color: #fff !important;
}

.creation_top li a label {
  cursor: pointer;
}

.btn_back svg {
  fill: #fff;
}

.inline-block {
  display: inline-block;
}

.wallet_popup .modal-content {
  border-radius: 0px;
  overflow: hidden;
  min-height: auto;
  height: auto;
  position: fixed;
  width: 25%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 400px;
}

.modal-body.p-2 {
  padding: 20px 0px !important;
}

.wallet_popup .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 8px 15px;
  opacity: 1;
}

/** selection **/
::selection {
  color: #fff;
  background: #99ca3c;
}

.home_posts {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 175px;
}

.zoom-in-out-box {
  margin: 24px;
  width: 250px;
  height: 250px;
  animation: zoom-in-zoom-out 1s ease infinite;
  position: fixed;
  z-index: 2;
  bottom: 0px;
  right: 0px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/** fonts **/


.container_for_competition_result.container .accordion-header,
.win_popup_heading_in {
  font-family: "GREATVIBESREGULAR";
}

.view svg {
  fill: #99ca3c;
}

.delete svg {
  fill: #f00;
}

.advance_search_box .post_file_viewer {
  max-height: 192px;
  overflow: hidden;
}

.advance_search_box .post_file_viewer iframe {
  max-height: 192px;
}

.videos_box video {
  max-height: 330px !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #99ca3c;
}

.win_popup_heading_in h4 {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

/** fonts **/
.container_for_competition_result.container .accordion-header,
.win_popup_heading_in {
  font-family: "GREATVIBESREGULAR";
}

.banner_content h1,
.video_text h1,
.welcome_section h2,
.video_text h2,
.subscription_section h1,
.post-image-box h2,
.newandfeed h1,
.holder_name,
.post_image h1,
.trend-title,
.about_text h3,
.top_search h2,
.terms h2,
.terms h6,
.terms h3,
.contact-form h2,
.faq_section h3,
.faq_section h1,
.privacy_policy h2,
.privacy_policy h3,
.terms h1,
.blog_heading,
.blog_main form h4,
.welcome_section_popup h2,
.latest_news li a h2,
.contact-form.contact_info h1,
.client_testimonial h1,
.new_banner_text h2,
.new_about_text h2 {
  /* font-family: "GARABD"; */
  font-family: Arial, Helvetica, sans-serif;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/** about page **/
.top_search {
  margin-top: 0px;
}

.top_search h2 {
  color: #000;
}

.top_search label {
  position: relative;
  display: block;
}

.top_search .form-control {
  background-image: url("../../web_img/arrow_dropdown.png");
  background-position: 95% 53%;
  background-repeat: no-repeat;
  background-size: 15px;
  border-radius: 50px;
  display: inline-block;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  padding-left: 40px;
  position: relative;
}

.form-control:focus,
.form-control1:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.top_search label img {
  position: absolute;
  left: 7px;
  max-height: 25px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 2;
}

.clear {
  clear: both;
}

.cmn_box {
  padding: 20px;
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
}

.top_search .cmn_box {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 305px;
  margin-top: 25px;
}

.top_search .cmn_box h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.top_search .cmn_box p {
  font-size: 15px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 0px;
  border-top: 1px solid #ededed;
  padding-top: 6px;
}

.top_search .cmn_box p::before {
  content: "";
  position: absolute;
  left: 0;
  font-size: 50px;
  top: -44px;
}

.top_search .cmn_box h3 {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 20px;
}

.about_text {
  text-align: center;
}

.about_text h2 {
  font-weight: normal;
  margin-bottom: 20px;
  text-align: center;
  font-size: 22px;
}

.about_text h2 strong {
  font-size: 25px;
  font-weight: 600;
  color: #99ca3c;
  line-height: 45px;
}

.btn {
  border: none;
  padding: 5px 20px;
}

.btn-success {
  background-color: #99ca3c;
}

.btn-success:hover,
.btn-success:focus {
  color: #fff;
  background-color: #84bc1c;
  border-color: #84bc1c;
}

.filter_button {
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

.org_tab .nav-tabs {
  width: 100%;
  border: none !important;
  background: #fff;
  color: #000;
  margin-top: 20px;
  padding: 10px 0;
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  border-radius: 0px;
}

.org_tab .nav-tabs li {
  font-size: inherit;
}

.org_tab .nav-tabs .nav-link:focus,
.org_tab .nav-tabs .nav-link:hover,
.org_tab .nav-tabs .nav-link.active {
  border-color: transparent;
  color: #000;
}

.org_tab .nav-tabs .nav-link {
  color: #000;
  margin-right: 0;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 !important;
}

.org_tab .nav-tabs .nav-link {
  color: #7c7c7c;
}

.org_profile_name {
  position: absolute;
  left: 170px;
  top: 65px;
}

.org_cover {
  position: relative;
}

.org_cover p {
  margin: 0;
  padding: 0;
  font-size: 30px;
  color: #000 !important;
}

#createPost.post-area_comment {
  padding: 15px 15px;
  margin-bottom: 20px;
  margin-top: 0;
}

.post-area_comment textarea.form-control {
  height: 50px;
  resize: none;
}

.edit_cover_pic_btn svg {
  fill: #fff;
  width: 20px;
}

.all_photos {
  background: #fff;
  padding: 15px;
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
}

.cmn_box {
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
}

.cmn_box p {
  font-size: 16px;
}

.photos_heading {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.photos_heading h5 {
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 15px;
}

.seeall {
  cursor: pointer;
}

.seeall:hover {
  text-decoration: underline;
}

.photos_books a {
  display: inline-block;
  width: 99px;
  height: 99px;
  margin: 1px 3px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.photos_books h4 {
  text-align: center;
  padding: 10px;
  color: #0008;
}

.join_won_org {
  position: absolute;
  top: 0;
  right: 35px;
  overflow: hidden;
}

.join_won_org a {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dbdbdb+1,ffffff+100 */
  background: #dbdbdb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #dbdbdb 1%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #dbdbdb 1%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #dbdbdb 1%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dbdbdb', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-right: 10px;
  text-align: center;
}

.join_won_org p {
  line-height: 22px;
  color: #000;
  font-size: 18px;
  padding-top: 15px;
}

.join_won_org p b {
  display: block;
  text-transform: uppercase;
}

.join_won_org span {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #d1d1d1;
  font-weight: bold;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  line-height: 40px;
  margin: 10px auto;
}

.btn_follow {
  position: absolute;
  top: 45px;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  text-align: center;
}

.btn_follow a {
  margin: 0 5px;
}

.btn_follow button {
  padding: 8px 15px;
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
}

.btn_follow svg {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  fill: #fff;
  margin-right: 2px;
}

.recent_post_view_detais {
  text-align: right;
  display: block;
}

#header .dropdown:hover .dropdown-menu {
  display: block;
  left: 15px;
  padding: 0;
}

#header .dropdown .dropdown-menu li {
  line-height: 32px;
}

#header .dropdown .dropdown-menu li a {
  padding: 2px 10px;
}


.bg-dark {
  background-color: #2a2a2a !important;
}

.n_make_money p {
  font-size: 25px;
}

#header .dropdown-menu {
  position: absolute;
  padding: 15px 12px 15px 12px;
  top: 40px;
}

#header .dropdown-item:focus,
.dropdown-item:hover {
  background-color: #000000;
  border-radius: 0;
  color: #fff !important;
}

.dropdown-toggle::before,
.dropdown-toggle::after {
  display: inline-block;
  /* margin-left: 0.255em; */
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 16px; */
  /* top: 0; */
  position: absolute;
  right: 0px;
  top: 30px;
}

.dropdown-toggle::before,
.dropdown-toggle::after {
  display: inline-block;
  /* margin-left: 0.255em; */
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  /* right: 16px; */
  /* top: 0; */
  position: absolute;
  right: 0px;
  top: 30px;
}

.dropdown::before {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  right: 16px;
  top: 0;
  position: absolute;
  right: 0px;
  top: 30px;
  color: rgb(153 202 60);
}

.dropdown-toggle.remove_drop::before {
  border: none !important;
}

.remove_drop.dropdown::before {
  border: none !important;
}

.edit_drop div {
  cursor: pointer;
}

.dropdown-menu,
.dropdown-item {
  padding: 0;
}

.dropdown-item {
  padding: 0 10px;
}

.dropdown-menu a {
  vertical-align: inherit;
  position: relative;
}

.dropdown-menu a span {
  position: absolute;
  right: 10px;
}

.cover_link {
  right: 8%;
  bottom: 10px;
  z-index: 99;
}

.modal_aboutme .modal-content {
  border-radius: 0px;
  overflow: hidden;
  min-height: 400px;
  max-height: 80%;
  position: fixed;
  width: 75%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
}

.modal_aboutme .modal-body {
  padding: 20px 15px !important;
}

.about-title h1 {
  color: #535353;
  font-size: 45px;
  font-weight: 600;
}

.about-title span {
  color: #AF0808;
  font-size: 45px;
  font-weight: 700;
}

.about-title h3 {
  color: #535353;
  font-size: 23px;
  margin-bottom: 24px;
}

.about-title p {
  color: #7a7a7a;
  line-height: 1.8;
  margin: 0 0 15px;
}

.about_details_edit {
  right: 15px;
  top: 5px;
}

@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }

  .banner_bottom_bar {
    width: 891px;
  }

  .banner_bottom_bar::before {
    width: 85%;
  }

  .our_client.new_banner_slider {
    height: 350px;
    margin-bottom: 55px;
  }

  .client_testimonial {
    min-height: 285px;
    margin-bottom: 20px;
  }

  .client_testimonial h1 {
    margin: 0 0 25px 0;
  }

  .new_home p {
    line-height: 30px;
  }

  .client_name {
    line-height: 0;
  }

  .client_name {
    top: 0;
  }
}

@media only screen and (max-width: 1790px) {
  .banner_bottom_bar::before {
    width: 85%;
  }

  .banner_bottom_bar {
    width: 760px;
  }
}

@media only screen and (max-width: 1680px) {
  .banner_bottom_bar {
    width: 600px;
  }

  .banner_bottom_bar::before {
    width: 78%;
  }
}

@media only screen and (min-width: 320px) {
  /* .container {
    max-width: 95%;
  } */
}

@media only screen and (max-width: 1500px) {
  .new_banner_img {
    right: 0;
  }
}

@media only screen and (max-width: 1400px) {
  .banner_bottom_bar {
    width: 473px;
  }

  .banner_bottom_bar::before {
    left: -60px;
  }

  .new_banner_img {
    width: 310px;
    bottom: 8px;
  }

  /** new home **/
  .home_login_new .form-outline {
    margin-bottom: 15px !important;
  }

  .home_login_new h6 {
    margin-bottom: 0px;
  }

  .some_member_post_img li {
    width: 41.17% !important;
    height: 170px;
  }

  .your_work {
    line-height: 25px;
  }

  .n_member_posts h2 {
    line-height: 30px;
  }

  .n_member_posts p {
    line-height: 22px;
  }

  .btn_new {
    margin-top: 5px !important;
  }

  .your_work_position_height {
    height: 305px;
  }

  .whatisallphanes video {
    height: 225px;
  }

  .n_make_money_list {
    margin-top: 15px !important;
  }

  .n_make_money_list li {
    padding: 5px 0 0.2px 0;
  }

  .n_make_money_list li p {
    line-height: 25px;
  }

  .footer_social_link img {
    width: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .new_banner_img {
    width: 225px;
    bottom: auto;
    top: 5px;
  }

  .client_testimonial h1,
  .new_banner_text h2,
  .new_about_text h2 {
    font-size: 22px;
  }

  .over_text h2 {
    font-size: 24px;
  }

  .new_home p {
    line-height: 18px;
    font-size: 16px;
  }

  .login_or {
    /* width: 33px;*/
    height: 33px;
    margin: 0 auto;
  }

  .line {
    width: 87px !important;
    height: 1px !important;
    background-color: #000 !important;
  }

  .kep-login-facebook.metro,
  .kep-login-facebook {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1262px) {
  .book_list_box {
    width: 16%;
  }

  .booklist_img {
    height: 146px;
  }
}

@media only screen and (max-width: 1200px) {
  .trend-sec-box {
    flex-wrap: wrap;
  }

  .middle-sec-box {
    overflow-x: hidden;
    overflow-y: visible;
  }

  .trend-video {
    width: 100%;
    max-width: 100%;
    max-height: 180px;
  }

  .trend-video img {
    max-width: 100%;
  }
}

/* @media only screen and (max-width: 768px) { 
    
} */
/* @media only screen and (max-width: 600px) {
    
} */

/* for more than 14 inch screen  */
@media only screen and (min-width: 1400px) {
  .view-post-img img {
    max-height: 600px;
  }
}

.err-msg2 {
  width: 100%;
  background-color: #c4c4c4;
  color: red;
  padding: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.terms {
  padding: 20px 0;
}

.terms ul li {
  font-size: 20px;
  font-weight: bold;
}

.terms ul li li,
.terms ul.information_col li {
  font-weight: normal;
  font-size: 16px;
}

.privacy_policy p {
  margin-bottom: 15px;
}

.read-more {
  text-align: end;
  margin-top: 5px;
  cursor: pointer;
}

.read-or-hide {
  color: blue;
  cursor: pointer;
}

pre {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

#banner {
  height: 73vh;
  display: flex;
  align-items: center;
  /* background-color: red; */
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 40%;
}

.banner-img {
  border-radius: 50%;
}

.center-item {
  display: flex;
  align-items: center;
  /* background-color: blue; */
  height: 100%;
  /* font-size: 40px; */
}

.render_fr {
  margin-top: -10px;
}

.flex_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

/* .clr-1{
    color: #CB58AB;
}

.pd-0{
    padding: 0;
} */

/* .ff-1{
    font-family: caramel;
    font-style: italic;
} */

#tbd {
  padding: 80px 40px;
  border-bottom: 1px solid #0004;
}

#notfound {
  min-height: 60vh;
}

.tbd {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
}

.circles {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 10px;
}

/* .circles img{
    border-radius: 50%;
    height: 100%;
    width: 100%;
} */

/* .pd-0{
    padding: 0;
    margin: 0;
}
*/
.fea-img {
  max-width: 100%;
}

.modal_header {
  padding: 5px 20px;
  border-bottom: 1px solid #0002;
  display: flex;
  text-align: center;
}

.modal-content {
  border-radius: 0px;
  overflow: hidden;
  min-height: 400px;
  height: 100%;
  position: fixed;
}

.modal.show .modal-dialog {
  transform: none;
  height: 100%;
}

/* .trends-tag{
    position: relative;
}
.share-tag{
    /* position: absolute;
    right: 8px; 
} */

.pro_img img {
  height: 40px;
  width: 40px !important;
  border-radius: 50%;
}

.online-Profile img {
  width: 35px !important;
  height: 35px !important;
}

.profile_part {
  display: flex;
  gap: 10px;
  align-items: center;
}

.gal_frame {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  width: 100%;
}

.photo_frame {
  max-width: 60px;
  width: 60px;
  height: 78px;
  background-color: #cac8c8;
}

.modal_close_btn {
  position: absolute;
  top: 6px;
  right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #0006;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

pre {
  max-width: 100ch;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.modal_image_input.active .items_center {
  position: absolute;
  bottom: -54px;
  left: 0px;
}

.modal_image_input.active .max_width {
  display: none;
}

.modal_image_input label {
  background: #0d6efd;
  display: table;
  color: #fff;
  cursor: pointer;
  padding: 6px 24px;
  border-radius: 30px;
}

.modal_image_input_1 label {
  cursor: pointer;
  margin-top: 3px;
  padding: 5px 15px;
  border-radius: 0px;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.c_input_box label {
  min-width: 140px;
}

.modal_image_input_1 label:hover {
  border: none;
  background: #f7f7f7;
}

.modal_image_input input[type="file"],
.modal_image_input_1 input[type="file"] {
  display: none;
  cursor: pointer;
}

.members-sec {
  background-color: white;
  margin-top: 15px;
  border-radius: 5px;
  height: auto;
  margin-bottom: 15px;
}

.add-link {
  color: rgb(112, 184, 81);
  padding-top: 6px;
}

.add-btn {
  border-radius: 50px;
  background: rgb(235 235 235);
  height: 30px;
  /* padding: 10px; */
  /* box-shadow: 1px 1px grey; */
  padding-bottom: 29px !important;
  border: white;
  color: rgb(112, 184, 81);
}

.unfriend-btn {
  border-radius: 50px;
  background: whitesmoke;
  height: 30px;
  color: #000;
  /* padding: 10px; */
  /* padding: 10px 0px 0px -1px !important; */
  box-shadow: 1px 1px grey;
  padding-bottom: 29px !important;
}

/* .gallery-sec{
    
} */
.gallery_sec {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
}

.gallery_img_box {
  overflow: hidden;
  border-radius: 7px;
  padding: 5px;
  /* background-color: red; */
}

.gallery-img {
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

#features h1 {
  font-family: "Berkshire Swash", cursive;
  font-size: 62px;
  position: relative;
  /* color: #99ca3c; */
}

.features_propraty h2 {
  font-family: "Berkshire Swash", cursive;
  font-size: 62px;
  color: #99ca3c;
}

.features_propraty h4 {
  /* font-size: 62px; */
  font-family: "Open Sans", sans-serif;
}

.add_comment {
  border-top: 1px solid #0001;
  align-items: center;
  min-height: 40px;
  padding-top: 10px;
}

.comment {
  width: 100%;
  padding: 5px 15px;
  border: none;
  background: none;
}

.comment:focus {
  outline: none;
}

.commentPost {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  background: #99cb35;
  padding: 5px 10px;
}

.view_name {
  font-weight: 600;
}

.post_img_1 {
  width: 100%;
}

.post_icons {
  font-size: 20px;
  margin-bottom: 0px;
  color: #c1c1c1;
}

.likes_count {
  /* margin-bottom: 10px; */
  color: rgb(46, 45, 45);
  /* font-weight: 500; */
  font-size: 14px;
}

.commentx {
  cursor: pointer;
}

.user_likes_details {
  margin-top: 5px;
  padding: 10px;
}

.user_likes_details .pro_img {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 6px;
}

.user_likes_details .pro_img:last-child {
  border-bottom: 0px !important;
}

.cmmt_user {
  font-weight: 600;
  font-size: 15px;
}

.comment_count {
  color: black;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  padding-right: 3px;
  text-decoration: none;
}

.comment_count.comment_msg {
  text-decoration: none;
}

.comment_count:hover {
  text-decoration: none;
}

.cmmt_text {
  color: rgb(49, 37, 37);
}

.trend-video {
  /* max-width: 140px;
    min-width: 130px; */
  min-width: 160px;
  background: #ffffff;
}

.trend-sec-box {
  display: flex;
  gap: 10px;
  width: 100%;
  min-height: 130px;
  background: none;
  /* padding: 6px; */
  margin-top: 10px;
  overflow: hidden;
  padding: 10px;
  border-radius: 0px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 15px;
}

.bg_5 {
  background-color: #fdfdfd;
}

#blog_details {
  /*font-family: "Georgia", "Times New Roman", Times, serif;*/
}

#blog_details h2 {
  font-weight: 800;
}

#blog_details p,
#blog_details li {
  font-size: 18px !important;
  text-align: justify;
}

#blog_details h1,
#blog_details h2,
#blog_details h3 {
  font-size: 24px;
}

#blog_details figure {
  padding: 20px 0;
  text-align: left;
}

.blog_icon {
  width: 100%;
  height: 260px;
  overflow: hidden;
}

/* c4c9d9 */

/* c4c9d9 */

#blog_details figure img {
  max-height: initial;
  max-width: 100%;
}

.description-title {
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.trend-video-description {
  width: 100%;
  background: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.post-area .profile-photo2 {
  max-width: 120px;
  height: 108px;
}

.post_img {
  /* min-height: 380px; */
  border-right: 1px solid #0001;
}

.post_img img {
  background-size: contain;
  /* min-height: 430px; */
  background-position: center;
  width: 316px;
}

#features h1 {
  font-family: "Berkshire Swash", cursive;
  font-size: 54px;
  position: relative;
  /* color: #99ca3c; */
}

.features_propraty h2 {
  font-family: "Berkshire Swash", cursive;
  font-size: 62px;
  color: #99ca3c;
}

.features_propraty h4 {
  /* font-size: 62px; */
  font-family: "Open Sans", sans-serif;
}

.block_content {
  /* background-color: #0071BE; */
  background-color: #99ca3c;
  min-height: 600px;
  color: #fff;
}

.block_content .row {
  display: flex;
  min-height: 600px;
  align-items: center;
}

.block_content h1 {
  font-family: "Berkshire Swash", cursive;
  font-size: 62px;
  color: #fff;
}

ul.big_style_ul li {
  font-size: 24px;
  list-style-type: square;
  color: #fff;
}

.commentsDiv {
  width: 100%;
  padding: 5px 15px;
  display: flex;
}

.width-100 {
  width: 100%;
}


.commentsView {
  font-size: 14px;
  width: 100%;
}

.user_name,
.user_name a {
  display: inline-block;
  font-weight: 600;
  padding-right: 5px;
  font-size: 14px;
}

.fs-14 {
  font-size: 14px !important;
}

.canvas_btn button {
  padding: 3px !important;
}

.viewD {
  width: 100%;
}

.canvas_com {
  background-color: #ebebeb;
  border-radius: 5px;
  margin-right: 5px;
}

.commentBox {
  margin-left: 10px;
  background-color: rgb(255 255 255);
  border-radius: 0;
  padding: 5px 10px;
  padding-bottom: 8px;
  /*border: 1px solid #e7e7e7;*/
}

.profile_gall {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.profile_gall_head {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #0001;
  align-items: center;
}

.user_profile_tag_sec {
  border-top: 1px solid #0001;
  display: flex;
  justify-content: space-between;
}

.user_profile_info_tags {
  display: flex;
  gap: 5px;
}

.pro_info_tag:hover {
  background-color: #dfdfdf;
  color: #000 !important;
}

.pro_info_tag.active {
  color: green;
  border-bottom: 2px solid green;
}

.photo_tag {
  font-size: 19px;
  font-weight: 700;
}

.like_link {
  color: #000;
}

.like_link:hover {
  color: #000;
  text-decoration: underline;
}

.user_photos_sec {
  display: flex;
  padding: 15px 5px;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 10px;
}

.user_photos {
  background-color: #ffffff;
  width: calc((100% / 3) - 5px);
  border-radius: 5px;
  min-height: 106px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.user_photos img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.pd-r {
  padding: 0;
  padding-right: 15px;
}

.profile_right_bar {
  background-color: #ffffff;
  height: 150px;
  border-radius: 5px;
  padding-right: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: grid;
  place-items: center;
}

.bb-1 {
  padding-bottom: 5px;
  border-bottom: 1px solid #0001;
}

.bb_1 {
  border-bottom: 1px solid #0001;
}

.fs_12 {
  font-size: 12px !important;
}

.like_box,
.comments_Area {
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fs_16,
.fs_16 .text {
  font-size: 16px !important;
}

.text_readmore {
  font-size: 15px !important;
  /* min-height: 150px; */
}

.advance_serch .text_readmore {
  max-height: 145px;
  overflow: hidden;
}

.momment_timex {
  font-size: 12px;
  color: gray;
  font-weight: 600;
  /* margin-left: 10px; */
}

.bg_10 {
  background-color: rgb(71, 71, 136);
}

.comment1 {
  border-radius: 20px;
  margin: 0 10px;
  font-size: 16px;
  background: none;
}

.h-underline {
  cursor: pointer;
}

.h-underline:hover {
  text-decoration: underline;
}

.replyComment {
  margin-left: 20px;
  margin-top: 5px;
}

/* members  */
.members_sec {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding-left: 10px;
}

.members {
  width: calc((100% / 2) - 10px);
  background-color: rgb(243 243 243);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  margin-bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 5px;
}

.member_pro_img img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.member_pro_name {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: normal;
  margin-bottom: 8px;
  color: #000;
}

.pro_details {
  background-color: #eee;
  text-align: center;
}

.kk_btns .btn {
  width: 100%;
  border-radius: 12px;
}

.kk_btns .btn.btn-success {
  background-color: #99ca3c;
  border-color: #99ca3c;
}

.kk_btns .btn:hover {
  background-color: #ccc;
  border-color: #ccc;
}

.outline_1 {
  outline: 1px solid #0001;
}

.member_pro_name:hover {
  color: #000;
  text-decoration: underline;
}

.view_profile {
  font-size: 15px;
  font-weight: 500;
}

.view_profile:hover {
  text-decoration: underline;
}

.acc_btns {
  display: flex;
  gap: 10px;
  /* flex-wrap: wrap; */
}

.t_cap {
  text-transform: capitalize;
}

/* end members  */

#leftBar {
  position: fixed;
}

.post_category_sec {
  display: flex;
  /* background-color: red; */
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #0002;
  justify-content: space-between;
  gap: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.post_category_sec select {
  border: none;
  width: 100%;
  height: 44px;
  padding: 0 10px;
  /* background-color: red; */
  border-radius: 5px;
  background: none;
  border-right: 1px solid #f5f5f5;
  border-radius: 0px;
}

.post_category_sec select:focus {
  /* outline: 1px solid #7dc1e7;*/
  outline: none;
}

.post_label {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 500;
  display: none;
}

.post_form br {
  display: none;
}

.c_pointer {
  cursor: pointer;
}

.mh_100 {
  min-height: 100vh;
}

/* search */
.search_body {
  background-color: #fff;
  min-height: 45vh;
  border-radius: 5px;
  padding: 20px;
}

.cpa {
  text-transform: capitalize;
}

/* end search  */

/* loader  */

/*
The loaders use CSS custom properties (variables) to control the attributes of the loaders
*/
:root {
  --loader-width: 25px;
  --loader-height: 25px;
  --loader-color-primary: #27ae60;
  --loader-color-secondary: #eee;
  --line-width: 3px;
  --animation-duration: 2s;
  --loader-initial-scale: 0.1;
}

.loader,
.loader:before,
.loader:after {
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

/*
In order to get optimal results, please only change the 
variables above and don't change anything in the actual loader code
*/

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader.simple-circle {
  transform-origin: center center;
  border: var(--line-width, 4px) solid var(--loader-color-secondary, #fff);
  border-right-color: var(--loader-color-primary, #00f);
  width: var(--loader-width, 100px);
  height: var(--loader-height, 100px);
  border-radius: 50%;
  animation: circle-loader var(--animation-duration, 1s) infinite ease-out;
}

.kb_1 {
  border-top: 1px solid #0002;
}

/* end loader  */
.share_body {
  padding: 0 0px;
}

.bg_2 {
  background-color: #ece9e9;
}

.btl_1 {
  border-top-left-radius: 5px;
}

.btr_1 {
  border-top-right-radius: 5px;
}

.bbr_1 {
  border-bottom-right-radius: 5px;
}

.bbl_1 {
  border-bottom-left-radius: 5px;
}

.br_1 {
  border-radius: 5px;
}

.bl_1 {
  border-left: 1px solid #0002;
}

.post_icons i {
  border-radius: 1px solid red;
}

.share_sec {
  position: relative;
  border: 1px solid #0003;
  border-radius: 5px;
}

.border_1 {
  outline: 1px solid #0002;
}

.border_r5 {
  border-radius: 5px;
}

.fs_choto {
  font-size: 14px;
}

.settings_row label {
  font-size: 14px;
  font-weight: 600;
}

.cl_gray {
  color: gray;
}

.info_title {
  font-size: 15px;
  font-weight: 600;
}

.copy_div {
  position: relative;
  height: 100%;
}

.copy_icon {
  /* background-color: red; */
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-left: 1px solid #0001;
}

.copy_icon:hover .post_icons {
  color: #000;
}

.form-select {
  height: 43px !important;
}

.settings_row {
  padding: 20px;
  padding-bottom: 60px;
}

.create_post_img__1 {
  margin-top: 10px;
  position: relative;
  width: auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: #000;
}

.create_sub_form select {
  height: 35px;
  border-radius: 0px;
  margin-top: 5px;
}

.form_basic {
  width: 100%;
  background-color: red;
  display: flex;
  justify-content: center;
}

.kk_check {
  width: 20px !important;
  margin-right: 5px;
}

.dom_title {
  padding: 10px;
  border-right: 1px solid #0002;
  font-weight: 600;
}

.box_1 {
  background-color: #fff;
  border-radius: 5px;
}

.cat_title {
  color: #000;
}

.category_box1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 15px 20px;
  /* padding: 10px; */
}

.category_box1 .category_in_box {
  padding: 10px;
}

.category_box {
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 15px 20px;
  /* padding: 15px 0; */

  height: 0px;
  overflow: hidden;
}

.category_box.active {
  padding: 15px 0;
  height: 100%;
}

.category_in_box {
  width: calc(100% / 5 - 20px);
  /* padding: 15px; */
  border-radius: 5px;
  outline: 1px solid #0003;
  position: relative;
}

.action_icons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 5px;
}

.action_icons .c_pointer {
  font-size: 15px;
}

.cl_r {
  color: red;
}

.like svg {
  width: 20px;
  margin-top: 3px;
}

.commbox .like svg {
  width: 15px !important;
}

.commbox .like {
  font-weight: 600;
  color: rgb(77, 76, 76);
}

@media only screen and (max-width: 1200px) {
  .kk {
    width: 33% !important;
  }
}

.popup_image_section video,
.popup_image_section iframe,
.advance_serch_img video,
.masonry_wrapper video {
  max-width: 100% !important;
  max-height: 100%;
}

@media only screen and (max-width: 800px) {
  .kk {
    width: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .kk {
    width: 100% !important;
  }

  #banner {
    background-image: none !important;
  }

  .features_propraty h2 {
    font-size: 34px;
  }

  .category_in_box {
    width: calc(100% / 2 - 10px);
    padding: 10px;
  }

  #leftBar {
    display: none;
  }

  .block_content h1 {
    font-size: 34px;
  }

  .banner_content h2 span {
    margin-left: 15px;
    margin-top: 15px;
  }
}

/* settings  */
.p_set_box {
  /* background-color: blue; */
  height: 45px;
  border-bottom: 1.5px solid #0002;
}

.p_set_navlink {
  display: flex;
  align-items: center;
  /* background-color: red; */
  padding: 10px 20px;
  height: 46px;
  color: #747373;
  font-weight: 600;
}

.btn_follow button {
  margin: 0 5px;
  /* font-size: 14px; */
}

.p_set_navlink:hover {
  color: #474545;
}

.form_image {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  position: relative;
  background-color: rgb(212, 211, 211);
}

.report_info {
  width: 100%;
  min-height: 300px;
  padding: 10px;
  padding-top: 0;
}

.blog_body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.form_preview_img {
  position: absolute;
  background-color: rgb(212, 211, 211);
  height: 100%;
  width: 100%;
  z-index: 1;
  display: grid;
  place-content: center;
}

.form_preview_img img {
  /* height: 100%; */
  max-height: 300px;
  /* width: 100%; */
  /* margin: auto; */
}

.modal_image_input {
  z-index: 2;
}

.pr_saved {
  color: gray;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  transition: 0.6s;
}

.pr_saved_1 {
  color: gray;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 5px;
  width: max-content;
  display: flex;
  align-items: center;
  transition: 0.6s;
  position: absolute;
  bottom: 10px;
}

.fs_tt {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.6px;
}

.not {
  padding: 10px;
  outline: 1px solid #0002;
}

.closeNot {
  width: 40px;
  outline: 1px solid #0002;
  padding: 10px;
  background-color: gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.p_set_navlink.active {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid #000;
}

/* end settings  */

/* page  */
.labels {
  font-weight: 600;
}

.outline_1 {
  outline: 1px solid #0002;
}

.cl_sec {
  color: #747373;
}

.video_area {
  height: 250px;
  border: 1px solid #0001;
  background-color: gainsboro;
  border-radius: 5px;
}

.fs_s {
  font-size: 14px;
}

.page_cat {
  flex-wrap: wrap;
}

.wi_80 {
  min-width: 350px;
  right: -30px;
  font-size: 14px !important;
}

.wi_80 a {
  color: #000 !important;
  font-size: 15px !important;
  font-weight: 600;
}

.pos_r {
  position: relative;
}

.page_in_box {
  width: max-content;
  padding: 5px;
  cursor: pointer;
}

.page_in_box.selected {
  background-color: #0071be;
  color: #fff;
}

.sugg {
  color: rgb(25, 25, 209);
  font-weight: 600;
  cursor: pointer;
}

.sugg:hover {
  text-decoration: underline;
}

/* end page  */

@media only screen and (max-width: 1200px) {
  .category_in_box {
    width: calc(100% / 4 - 15px);
    padding: 13px;
  }

  #leftBar {
    display: none;
  }
}

.mt_67 {
  margin-top: 67px !important;
}

.post_card_img {
  background-size: contain;
  background-position: center;
  min-height: 100%;
  min-width: 100%;
}

.kk_modi {
  display: flex;
  /* background-color: red; */
  width: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  top: 90px !important;
}

.w-97 {
  width: 97%;
}

.bottom_login {
  margin-top: 10px;
  position: sticky;
  bottom: -180px;
  height: 150px;
  background-color: #fff;
  width: 100%;
  transition: 0.3s;
  bottom: 0px;
}

.marked_friend {
  /* background-color: white; */
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 19px;
  color: rgb(0, 119, 255);
  font-weight: 700;
  letter-spacing: 1px;
}

.marked_friend img {
  height: 100%;
}

.social_details {
  display: flex;
  gap: 0 25px;
  flex-wrap: wrap;
  /* margin-top: -65px; */
  position: relative;
}

.profile_outer_photo {
  z-index: 2;
  min-width: 140px;
  min-height: 140px;
  /* background-color: #FFFFFF; */
  border-radius: 50%;
  padding: 5px;
  margin-top: -60px;
  width: 140px;
  height: 140px;
  position: relative;
  /* overflow: hidden; */
  border: 5px solid #fff;
}

.profile_inner_photo .img_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-size: cover;
  background-image: url("https://res.cloudinary.com/dsg7oitoj/image/upload/v1656928534/e1duj9ammszikk0byrum.jpg");
  height: 130px;
  width: 130px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

/* .profile_inner_photo img {
	height: 130px;
	width: 130px; */
/* border-radius: 50%;
} */

.sticky_div {
  position: sticky;
  top: 100px;
  max-height: calc(100vh - 110px);
  border-radius: 5px !important;
  overflow-y: scroll;
}

.about_info {
  font-size: 15px;
}

.numb_d {
  font-size: 21px;
  font-weight: 650;
}

.sd_tex {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.org_info h1 {
  font-size: 20px;
  line-height: 14px;
}

.org_info p {
  font-size: 14px;
  font-weight: 500;
}

.about_details {
  background-color: #ffffff;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.about_text {
  font-size: 17px;
  /* text-align: justify; */
}

.member_box {
  overflow: hidden;
  border-radius: 12px;
}

.friend_img {
  height: 200px;
  overflow: hidden;
  position: relative;
  display: flex;
  background-size: cover;
  background-position: 0% 50%;
}

.friend_img .photo_box {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.friend_img img {
  height: 100%;
  min-width: 100%;
  background-size: contain;
  background-position: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pro_pro_details {
  padding: 10px;
}

.post_img_pro {
  width: 100%;
  height: 260px;
  border-radius: 5px;
}

@media only screen and (max-width: 500px) {
  .friend_img {
    height: 125px;
  }
}

.drop_down {
  cursor: pointer;
  margin-top: 5px;
  position: relative;
  width: 55px;
  height: 55px;
}

/*.drop_down img {
  width: 100%;
}*/

.cl_gr {
  color: #474545;
  font-size: 17px;
  letter-spacing: 2px;
}

.bb_kib {
  height: 80%;
}

.post_actions {
  position: relative;
  height: 100%;
  /* background-color: red; */
  width: 30px;
}

.fav_icon {
  color: red;
}

.post_profile {
  padding: 0px 10px;
  height: 100%;
}

.post_profile img {
  border-radius: 50%;
}

.filter_select {
  height: 75%;
  outline: none;
  border: none;
  padding: 0 5px;
  background: none;
}

.dropdown_post_actions {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: max-content;
  min-width: 130px;
  top: 70%;
  right: 0;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 999;
}

.post_actions:hover .dropdown_post_actions {
  opacity: 1;
  pointer-events: initial;
}

.dropdown_post_actions div {
  /* padding: 7px 15px; */
  border-top: 1px solid #0001;
}

.drop_down_list {
  padding: 7px 0px;
  text-align: center;
}

.dropdown_post_actions div:hover {
  background-color: gainsboro;
}

.drop_child {
  opacity: 0;
  pointer-events: none;
  background-color: #ffffff;
  position: absolute;
  min-width: 150px;
  width: max-content;
  right: -5px;
  margin-top: 5px;
  z-index: 999;
  border-radius: 10px;
  overflow: hidden;
  /* padding: 5px; */
}

.drop_down:hover .drop_child {
  opacity: 1;
  pointer-events: initial;
}

.drop_child div {
  color: #000 !important;
  border-top: 1px solid #0001;
  position: relative;
}

.drop_child a,
.drop_in_ch {
  display: flex;
  font-size: 18px !important;
  color: #000 !important;
  padding: 10px !important;
  width: 100%;
}

.drop_child a:hover,
.search_items:hover,
.drop_in_ch:hover {
  background-color: rgb(240, 238, 238);
}

.nav-link {
  margin-top: 16px;
  /* display: flex; */
  /* align-items: center; */
}

.search_nav {
  margin-top: 7px;
  padding-right: 20px;
  position: relative;
}

.search_res {
  position: absolute;
  background-color: #ffffff;
  margin-top: 5px;
  z-index: 10;
  width: 100%;
  max-height: 70vh;
  overflow-y: scroll;
}

.search_items {
  padding: 10px;
  border-top: 1px solid #0002;
  color: #000 !important;
}

.se_name,
.member_search {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.member_search {
  border-radius: 50px;
  border: none;
  outline: none;
  width: 240px;
  padding: 8px 10px;
  background: none;
  border: 2px solid #fff;
  color: #fff;
  font-weight: normal;
}

.drop_down_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.mb_10 {
  border: none;
  outline: none;
  width: 240px;
  padding: 8px 10px;
}

.member_nav_items a {
  color: #000;
  font-weight: 600;
  height: 100%;
  padding: 0 10px;
  background: #ececec;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 10px;
}

.member_nav_items a.active {
  color: rgb(255 255 255);
  background: #99ca3c;
}

.pd_0 {
  padding: 0;
}

.min_h {
  min-height: 400px;
}

.pos_rel {
  position: relative;
}

.member_search_1 {
  /* height: 35px; */
  border-radius: 15px;
  width: 260px;
  padding: 8px 10px;
  border: none;
}

.desk_none {
  display: none;
}

/*add css tia*/
#banner_top {
  height: 66vh;
  align-items: center;
  /* background-color: red; */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100%;
}

#background_bg {
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background-image: none !important;
}

.video_section {
  padding-top: 50px;
  padding-right: 50px;
  margin-bottom: 25px;
}

.video_section img {
  width: 100%;
  height: 100%;
}

.video_text {
  padding-top: 40px;
  padding-left: 50px;
}

.video_text h1,
.video_text h2 {
  font-size: 50px;
  font-weight: 600;
  color: #99ca3c;
  text-align: center;
  line-height: 40px;
}

.video_text h3 {
  font-size: 30px;
  margin: 0px;
}

.email_club {
  margin-top: 20px;
}

.email_club input[type="text"] {
  background: #99ca3c;
  border: none;
  height: 50px;
  outline: none;
  padding-left: 15px;
  width: 250px;
}

.email_club input[type="submit"] {
  background: #000;
  border: none;
  height: 50px;
  outline: none;
  vertical-align: top;
  width: 85px;
  color: #fff;
}

.video_text.about_text {
  padding: 0px;
  padding-right: 50px;
}

.video_text.about_text h1 {
  margin: 0px;
}

.welcome_section h2 {
  font-size: 40px;
  font-weight: 600;
  color: #99ca3c;
}

p,
li {
  font-size: 16px;
  /* line-height: 18px; */
}

.subscription_section {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  position: relative;
}

.subscription_section {
  margin: 40px 0px;
  padding: 100px 80px;
  position: relative;
}

.subscription_section h1 {
  font-size: 45px;
  font-weight: 600;
  color: #99ca3c;
  width: 70%;
  line-height: 45px;
}

.subscription_section .subscription_img {
  position: absolute;
  right: -225px;
  width: 40%;
  top: 40px;
  margin: 0 auto;
}

.subscription_section .subscription_img img {
  max-width: 100%;
}

.sound_section {
  text-align: center;
}

.sound_section img {
  width: 300px;
}

.subscription_section li,
.about_text li {
  list-style: none;
}

.w_100x {
  width: 100px;
  display: flex;
  justify-content: center;
}

.w_100p {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  padding: 5px;
}

.make-money-list li {
  background-repeat: no-repeat;
  background-size: 10px;
  padding-left: 20px;
  position: relative;
  background-position: 0% 4px;
  text-align: left;
}

.footer_bg {
  padding-bottom: 0px;
}

.cover_pic {
  background: #ccc;
  text-align: center;
}

.cover_pic img {
  width: 100%;
  max-height: 300px;
}

.upload_photo {
  position: absolute;
  z-index: 999;
  margin-top: -12px;
  right: 0;
  color: #fff;
}

.position_fixed {
  position: relative;
}

.upload_photo a {
  background: #3f5e03;
  padding: 5px;
}

/*my canvas page*/
.post_form .form-control {
  background-color: hsla(0, 0%, 100%);
  margin: 0 0 0 0;
  resize: none;
  border-radius: 0px;
  border: 0;
  height: 76px;
  background: #f3f3f3;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.post_form .form-control:focus,
.post_form .form-control:hover {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.post_form .form-control1 {
  background-color: hsla(0, 0%, 100%);
  margin: 0 0 0 0;
  resize: none;
  border-radius: 0px;
  border: 0;
  height: 35px;
  padding-left: 15px;
  background: #f3f3f3;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.post_form .form-control1:hover,
.post_form .form-control1:focus {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.post_form .form-control1:hover,
.post_form .form-control1:focus {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.post_action .btn-primary {
  color: #fff;
  background-color: #1b651c;
  border-color: #067a06;
  border-radius: 0px;
  opacity: 1;
}

.bg-white {
  background-color: hsla(0, 0%, 100%);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 0 0 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
  border-radius: 0px;
}

.trending_creations {
  max-height: 112vh;
  overflow-y: scroll;
}

.trend-sec {
  background-color: hsla(0, 0%, 100%);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 0 0 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.commentBox .h-underline {
  /* border: 1px solid #ccc; */
  padding: 2px 10px;
  background: #f1f1f1;
}

.commentBox .h-underline:hover {
  text-decoration: none;
}

.view-post p {
  font-size: 15px !important;
}

.post-action-sec {
  /*background: #000;*/
  color: #000;
  padding: 10px 10px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.copy_link {
  overflow-x: scroll;
  height: 100%;
  flex-grow: 1;
  /* background-color: red; */
  border-right: 1px solid #0002;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.copy_link::-webkit-scrollbar {
  display: none;
}

.box_outline {
  outline: 1px solid #0002;
  border-radius: 5px;
}

.main_logo img {
  width: 300px !important;
}

.f-logo img {
  height: 100px !important;
}

.post-action-sec svg {
  color: #000;
  width: 16px;
}

.close_commenot {
  color: #f00;
}

#YourIntro textarea {
  resize: none;
}

#YourIntro textarea:focus {
  background-color: hsla(0, 0%, 100%, 0.7);
  box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0 5px 0 rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.27);
  margin: 40px 0 0 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.post_main_col {
  overflow: hidden;
}

.post_drop_down {
  position: absolute;
  top: 10px;
  right: 0;
  align-items: center;
}

.kk:hover {
  color: #0071be;
}

.post_drop_down {
  position: absolute;
  top: 10px;
  right: 0;
}

.post-user-name {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.audio_view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.audio_view video {
  margin-top: -90px;
}

.post_drop_down .dropdown-toggle::after {
  content: none;
}

.organisation_list .post_drop_down .btn {
  padding: 0 !important;
}

.post-user-name p {
  font-size: 14px;
}

.post-user-name p svg {
  width: 18px;
  vertical-align: top;
  margin-left: 4px;
}

.main_search_nav .btn-primary {
  border-radius: 0px;
  margin-top: 10px;
  background-color: rgba(0, 0, 0);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
  padding: 10px 20px;
}

.main_search_nav .btn-primary:hover,
.main_search_nav .btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.5);
}

.flip_page .demoPage {
  background: #fff;
  background-color: rgba(255, 255, 255);
  box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -webkit-box-shadow: -1px 0px 5px 0px rgb(0 0 0 / 27%);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.27);
}

.flip_page_in {
  width: 500px;
  height: 500px;
  margin: 0 auto;
}

.stf__parent {
  margin: 0 auto;
  padding: 0px 0 50px 0;
}

.post-image-box .justify-content-between {
  position: relative;
  padding-bottom: 10px;
}

.post_box li {
  font-size: 15px;
}

.post_box h3 {
  font-size: 16px;
}

.momment_time {
  position: absolute;
  top: 20px;
  left: 50px;
  font-weight: normal;
  color: #000;
}

.c_point {
  cursor: pointer;
}

.cmn_btn {
  color: #fff;
  border: none;
  background: #000;
  border-radius: 5px;
  min-width: max-content;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.share_icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.cmn_btn svg {
  font-size: 16px;
}

.close_pop {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bg_b {
  background-color: #000 !important;
  color: #fff !important;
}

.edit_cover_pic_btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: 0px;
  background-color: #000;
  color: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50% !important;
  transition: 0.7s;
}

.saved_post.active svg {
  color: red;
}

.edit_cover_pic_btn:hover {
  transition: 0.7s;
  background-color: #00f;
}

.cmn_btn:hover {
  border: none;
  background: rgb(41, 41, 41);
  color: #fff;
}

.view_more {
  color: #000;
}

.footer-end {
  padding-top: 50px;
}

.profile_photo img {
  height: 45px;
  width: 45px;
  overflow: hidden;
  border-radius: 50%;
}

/* .profile_link{
	color: #000;
} */

.profile_link:hover {
  /* color: #000; */
  text-decoration: underline;
}

/* .share_box{
	overflow-y: scroll;
} */

.kkmodix {
  /* background-color: red; */
  height: 100px;
  overflow: hidden !important;
  margin-bottom: 10px;
}

.share_post_preview {
  overflow: hidden;
  border-radius: 10px;
  outline: 1px solid #0003;
  margin-top: 10px;
}

.share_type {
  margin-top: 5px;
  display: flex;
  gap: 5px;
  font-size: 14px;
  outline: 1px solid #0002;
  border-radius: 7px;
  align-items: center;
  font-weight: 500;
  padding: 3px 7px;
  width: max-content;
  color: #000;
  background-color: rgb(206, 204, 204);
}

.share_type svg {
  font-size: 14px;
}

.fw-500 {
  font-weight: 500;
}

.search_left {
  padding: 5px;
  position: relative;
}

.check_items {
  display: block;
  width: 100%;
  margin-left: 15px;
  line-height: 15px;
  margin-bottom: 0;
}

.sort_item .check_items {
  line-height: 18px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.sub_domain {
  padding-left: 10px;
  margin-top: 30px;
  text-transform: uppercase;
}

.left_search_btn {
  margin-left: 16px;
}

.demoPage img {
  max-height: 100%;
}

.org_nav {
  background-color: #fff;
}



/* home bg */
.home_bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.background_opacity {
  background-color: rgba(255, 255, 255, 0.5);
}

.footer_link li {
  list-style: none;
  position: relative;
  padding-left: 15px;
  line-height: 30px;
}

.footer_link li:before {
  background-image: url('../../web_img/f_new_arrow.png');
  content: "";
  position: absolute;
  left: 0px;
  top: 8px;
  width: 7px;
  height: 14px;
  background-repeat: no-repeat;
}

.footer_social_link img {
  width: 30px;
  margin: 0px 1px;
}

.n_make_money_list li {
  list-style: none;
  line-height: 45px;
}

.bg-success {
  background-color: #99ca3c !important;
}

.text-success {
  color: #99ca3c !important;
}

.some_member_post_img.new_winner li {
  float: left;
  width: 44.17%
}




.some_member_post_img li {
  width: 45.1%;
  height: 200px;
  list-style: none;
  float: left;
  margin: 15px;
}

.post_icon {
  width: 40px;
  height: 40px;
  line-height: 25px;
  right: -15px;
  top: -20px;
}

.post_icon img {
  width: 20px;
}

.some_member_post_img li a {
  list-style: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
}

.your_work_position {
  position: relative;
  margin-top: -110px;
  z-index: 1;
}

.your_work_position_height {
  height: 335px;
}

.login_home {
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  z-index: 2;
}

.home_login_new {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  height: 100%;
  width: 450px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}

.new_login_icon {
  right: 0;
  top: 0;
}

.text-right {
  text-align: right;
}

.home_login_new .form-control:focus {
  border: 0px;
  box-shadow: none;
}

.some_member_post_img.home_post_new li:nth-child(2) {
  margin-top: 40px;
}

.some_member_post_img.home_post_new li:nth-child(3) {
  margin-top: -10px;
}

.some_member_post_img.home_post_new li:nth-child(4) {
  margin-top: 15px;
}

.banner_img {
  height: 520px;
  background-position: center center;
  background-size: cover;
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
}



/* fonts */
h2,
.n_make_money_list li,
.btn_new,
.home_login_new h4,
.new_tesrmonial h3 {
  font-family: 'OSWALDREGULAR';
}

/* end user profile  */
@media (max-width: 1680px) {
  #banner_top {
    height: 700px;
  }

  #banner {
    height: 600px;
  }
}

@media only screen and (max-width: 1280px) {
  .plan_list li {
    height: auto;
    width: 30%;
    padding-bottom: 30px;
  }

  .plan_list_li {
    height: auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  #banner_top {
    height: 560px;
  }

  #banner {
    height: 560px;
  }
}

@media (max-width: 1199px) {
  #banner_top {
    height: 560px;
  }

  #banner {
    height: 560px;
  }

  .social_details {
    display: block;
    text-align: center;
  }

  .social_details a,
  .sd_tex {
    display: inline-block;
    padding: 0 5px;
    font-size: 15px;
  }

  .organiza_follow_us a {
    padding: 5px 15px;
  }

  .organization_post_left a {
    margin-right: 10px;
  }

  .organiza_follow_us img {
    width: 85px;
  }
}

@media only screen and (min-width: 1100px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 1080px) {
  #banner_top {
    height: 465px;
  }

  #banner {
    height: 465px;
  }

  .video_text {
    padding-left: 0px;
  }

  .about_text {
    margin-top: 50px;
  }

  .subscription_section .subscription_img {
    right: -60px;
  }

  .video_text h1,
  .video_text h2,
  .banner_content h1,
  .welcome_section h2,
  .subscription_section h1 {
    font-size: 40px;
  }
}

.stream_body {
  min-height: 70vh;
  display: grid;
  place-content: center;
}

.f-end a:last-child {
  border-right: 0px !important;
}

@media only screen and (max-width: 990px) {
  .mt_67 {
    margin-top: 24px !important;
  }

  #blog_details p {
    font-size: 19px !important;
    text-align: initial;
  }

  .trend-video {
    max-height: 300px;
  }

  .mpd_1 {
    padding: 0 5px;
  }

  .post_img_pro {
    height: 180px;
  }

  .friend_img {
    height: 150px;
  }

  .flex_wrap {
    flex-wrap: wrap;
  }

  .member_search_1 {
    width: 100%;
  }

  .show_responsive {
    display: flex !important;
  }

  .show_responsive li:first-child {
    width: 85px;
  }

  .show_responsive .nav-link {
    padding: 0 10px !important;
    list-style: none;
    border-bottom: 0px;
  }

  .desktop_g-play {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {
  .member_box .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }

  .win-popup_in {
    width: 835px;
  }

  .win_list_blog li:nth-child(1),
  .win_list_blog li:nth-child(3) {
    width: 295px;
  }

  .win_list_blog li:nth-child(2) {
    width: 355px;
  }

  .win_list_blog li:nth-child(2) h2 {
    top: 64px;
  }

  .win_list_blog li:nth-child(2) .wnr_profile_img {
    top: 132px;
  }

  .win_list_blog li:nth-child(1) {
    margin-right: 0;
    display: none;
  }

  .win_list_blog li:nth-child(3) {
    margin-left: -40px;
  }


}

#profile .col-lg-3 {
  flex: 0 0 auto;
  width: 20%;
}

#profile.my_canvas_section .col-lg-3 {
  width: 25%;
}

@media only screen and (max-width: 992px) {
  .creation_banner {
    margin-bottom: 0;
    margin-top: 10px;
  }

  #top_search_heading {
    min-height: 420px;
  }

  .popup_image_section {
    height: 400px !important;
    position: relative;
    width: 100% !important;
  }

  .line {
    width: 100% !important;
  }

  .home_popup .popup_hedign {
    position: relative;
    top: 0;
    margin-bottom: 10px;
  }

  .review_descrip {
    height: auto;
    overflow: hidden;
  }

  .client_testimonial {
    height: auto;
    padding-bottom: 10px;
  }

  /** new home **/
  .login_home,
  .home_login_new {
    position: relative !important;
    width: 100%;
  }

  .block_responsive {
    display: block !important;
  }

  .banner_img {
    height: 310px;
  }

  .some_member_post_img.new_winner,
  .some_member_post_img.home_post_new {
    text-align: center;
  }

  .some_member_post_img.new_winner li,
  .some_member_post_img.home_post_new li {
    float: none;
    display: inline-block;
  }

  .new_footer {
    text-align: center;
  }

  .footer_link li::before {
    background: none;
  }

  .new_footer .p-4 {
    padding: 0px !important;
    padding-bottom: 15px !important;
  }

  .n_make_money .mt-5 {
    margin-top: 0px !important;
  }

  .n_make_money img {
    margin-top: 30px !important;
  }

  .Winners_section {
    margin-top: 15px;
  }

  .n_member_posts .mt-4 {
    margin-top: 0 !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }

  .new_tesrmonial .carousel-control-prev {
    right: auto;
    left: 0;
  }

  .new_tesrmonial .carousel-control-next,
  .carousel-control-next,
  .carousel-control-prev {
    left: 50px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    bottom: -35px !important;
  }

  .my_space_links.position-absolute {
    position: relative !important;
    margin-bottom: 20px;
    top: 0;
    margin-top: -5px;
  }
}

@media only screen and (max-width: 991px) {
  .win-popup_in {
    width: 100%;
  }

  .win_list_blog li:nth-child(1),
  .win_list_blog li:nth-child(3) {
    width: 208px;
  }

  .win_list_blog li:nth-child(1),
  .win_list_blog li:nth-child(3) {
    width: 200px;
  }

  .win_list_blog li:nth-child(2) {
    width: 220px;
  }

  .win_list_blog li:nth-child(2) h2 {
    top: 64px;
  }

  .win_list_blog li:nth-child(2) .wnr_profile_img {
    top: 132px;
  }

  .win_list_blog li:nth-child(1) {
    margin-right: 0;
    display: none;
  }

  .win_list_blog li:nth-child(3) {
    margin-left: 0;
  }

  .win_list_blog li h2 {
    top: 35px;
  }

  .wnr_profile_img {
    top: 80px;
    width: 95px;
    height: 95px;
  }

  .win_list_blog li:nth-child(2) {
    width: 290px;
  }

  .create_right {
    padding: 50px 0px;
    padding-left: 100px;
  }

  .organization_post_left,
  .organiza_follow_us {
    top: 70px;
  }

  .organization_post {
    margin-top: 30px;
  }

  .fs-22,
  .banner_content h2,
  .video_text h3,
  p,
  li,
  .privecy_header,
  .privecy_footer,
  #top_search_heading h3,
  #top_search_heading h1,
  .about_text h2,
  .terms.privacy_policy p,
  .terms.privacy_policy ul li,
  .terms h6,
  .terms p {
    font-size: 16px !important;
    line-height: 26px;
  }

  .nav-middle.active {
    width: 100% !important;
  }

  .index-header ul li a {
    font-size: 16px !important;
  }

  .nav-link {
    padding: 0 10px !important;
  }

  .container_for_competition_result .win-popup_in .win_list_blog li:nth-child(1),
  .container_for_competition_result .win-popup_in .win_list_blog li:nth-child(3) {
    width: 200px;
  }

  .container_for_competition_result .win-popup_in .wnr_profile_img {
    top: 78px;
  }

  .win-popup_in.competition_result_in .win_list_blog li:nth-child(2) h2 {
    top: 50px;
  }

  .win-popup_in.competition_result_in .win_list_blog li:nth-child(2) span {
    top: 65px;
  }

  .win-popup_in.competition_result_in .win_list_blog li:nth-child(2) {
    width: 261px;
  }

  .win-popup_in.competition_result_in .win_list_blog li h2 {
    top: 33px;
  }

  .win-popup_in.competition_result_in .win_list_blog li span {
    top: 45px;
  }

  .win-popup_in.competition_result_in .win_list_blog li:nth-child(2) .wnr_profile_img {
    top: 110px;
  }

  .Login .login-form label {
    padding-left: 10px;
  }

  .btn_follow {
    position: relative;
    top: 0;
  }

  .top_videos {
    height: auto;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 900px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media only screen and (max-width: 768px) {
  .member_box .col-md-4 {
    flex: 0 0 auto;
    width: 33.333%;
  }

  #blog_details p {
    font-size: 17px !important;
  }

  .mb_w_100 {
    margin-top: 20px;
    width: 100%;
  }

  .desk_none {
    display: block;
  }

  .desk_none img {
    width: 100%;
    margin-top: 30px;
    display: none;
  }

  .mb_my_5 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mb_none {
    display: block;
  }

  .wi_80 {
    right: 0;
  }

  .px_0 {
    padding: 0;
  }

  .video_text h3,
  p,
  li {
    font-size: 20px;
  }

  #banner_top {
    height: 363px;
  }

  .banner_content h2 {
    line-height: 18px;
    padding-bottom: 25px;
  }

  .btn_follow {
    width: 100%;
  }

  .btn_follow a {
    margin: 0 1px;
  }

  .join_won_org {
    position: relative;
    right: 0;
    text-align: center;
    margin-top: 15px;
  }

  .join_won_org a {
    margin: 0 5px;
  }

  .december_winners .december_winners_img_box {
    position: relative;
    top: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .home_login .subscription_section {
    margin-top: 30px;
  }

  .home_login .subscription_section li {
    font-size: 18px;
    text-align: center;
    background-image: none !important;
    padding: 0;
    border-bottom: 1px solid #e3e3e3;
    padding: 6px 0;
  }

  .over_text {
    margin-top: 30px;
  }

  .over_text div {
    display: inline-block;
    width: 50%;
  }

  .our_client .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 50px;
    top: 40%;
  }

  .banner_bottom_bar {
    width: 100%;
    padding-right: 0px;
    padding-top: 1px;
  }

  .videos_new {
    margin-top: 30px;
  }

  .modal-content {
    overflow-y: scroll;
  }

  .mobile_login {
    display: block;
  }

  .desktop_login {
    display: none;
  }

  .organisation_list .d-flex {
    display: block !important;
  }

  .organisation_list .blog_heading {
    text-align: center;
    margin-bottom: 0px !important;
  }

  .organisation_list .org_tab .nav-tabs {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }

  .profile_cover {
    height: 25vh;
  }

  .social_details a,
  .sd_tex {
    font-size: 12px;
  }

  .social_details a,
  .sd_tex {
    padding: 0 3px;
  }

  #header .dropdown:hover .dropdown-menu {
    position: relative;
    left: 0;
    top: -11px;
  }

  #header .dropdown:hover .dropdown-menu li:hover a {
    background-color: transparent;
  }

  .nav-item.dropdown:after {
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
}

@media only screen and (max-width: 991px) {
  .category_list {
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .modal-content {
    position: relative;
  }

  .popup_image_section {
    height: 550px;
  }

  .popup_image_section img {
    max-height: 100%;
  }

  .home_login .login-form {
    margin-top: 0 !important;
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .subscription_section .subscription_img {
    right: 0;
  }

  .subscription_section .subscription_img {
    width: 35%;
  }

  .preserve_legacy_section {
    margin-top: 20px;
  }

  .book_list_box {
    width: 21%;
  }

  .booklist_img {
    height: 132px;
  }

  .modal-content {
    position: relative;
  }

  .like_box,
  .comments_Area {
    height: auto;
  }

  .popup_image_section img {
    position: relative;
  }

  .create_left p {
    padding: 0px 0px;
  }

  .create_right {
    padding-left: 0px;
  }

  .create_logo {
    max-width: 265px;
  }

  .create_left {
    padding-top: 20px;
  }

  .create_upload_img {
    margin: 0 auto;
  }

  .upload_profile {
    margin: 15px auto;
  }
}

@media screen and (max-width: 990px) {

  .desktop_none .desktop_none_hide {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .center-item {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }

  .video_text {
    padding-top: 35px;
    text-align: center;
  }

  .video_section {
    padding-right: 0;
  }

  .welcome_section {
    text-align: center;
  }

  .subscription_section .subscription_img {
    right: 0;
    position: relative;
    max-width: 100%;
    width: auto;
    margin-bottom: 20px;
  }

  .subscription_section h1 {
    width: 100%;
  }

  .subscription_section {
    position: relative;
    padding: 42px 0px;
    position: relative;
    text-align: center;
  }

  .center-item {
    background-color: transparent;
    padding: 20px;
  }

  .email_club input[type="text"] {
    margin-bottom: 10px;
  }

  .video_text.about_text {
    padding-right: 0;
  }

  .comment_top_nav {
    margin-top: 15px;
  }

  .comment_top_nav a img {
    width: 20px;
  }

  .newandfeed li {
    background-position: 0% 24%;
  }

  .post-image-box h2 {
    font-size: 24px;
  }

  .post_like_box svg {
    width: 15px;
  }

  .post-image-box p,
  .newandfeed li a {
    font-size: 16px;
  }

  .about_img img {
    width: 265px;
  }

  .top_search {
    text-align: center;
  }

  .f-end a {
    padding: 0 10px !important;
  }

  .contact_info .cmn_box {
    margin: 0;
    margin-bottom: 15px;
  }

  .contact-form.contact_info {
    padding: 0 15px;
  }

  .top_search .cmn_box {
    margin-top: 0px;
  }

  #top_search_heading {
    background-position: top;
    min-height: auto;
    padding-top: 20px;
    background: none;
  }

  #top_search_heading h3,
  #top_search_heading h1 {
    width: 100%;
  }

  .logo-top img {
    width: 150px;
    height: auto;
  }

  /** font size **/
  .terms h1 {
    width: 100%;
  }

  .terms {
    padding: 20px 20px;
  }

  .center-item {
    display: block;
    text-align: center;
  }

  .mobile_img {
    display: block;
  }

  #banner_top {
    height: 425px;
    background-repeat: repeat;
    background-size: cover;
  }

  .about_text {
    margin-top: 0;
    padding: 0 !important;
  }

  .subscription_section {
    margin: 0;
  }

  .subscription_section .subscription_img {
    margin-bottom: 0;
  }

  .sound_section {
    margin-top: 25px;
  }

  .subscription_section ul {
    margin-left: 0;
    padding: 0 15px;
  }

  .video_text h1,
  .video_text h2,
  .banner_content h1,
  .welcome_section h2,
  .subscription_section h1,
  .privacy_policy h2,
  #top_search_heading h2,
  .about_text h2 strong,
  .about_text h3,
  .privacy_policy h2,
  .terms h3,
  .terms h1 {
    font-size: 30px;
    line-height: 26px;
  }

  .fs-22,
  .banner_content h2,
  .video_text h3,
  p,
  li,
  .privecy_header,
  .privecy_footer,
  #top_search_heading h3,
  #top_search_heading h1,
  .about_text h2,
  .terms.privacy_policy p,
  .terms.privacy_policy ul li,
  .terms h6,
  .terms p {
    font-size: 16px !important;
    line-height: 26px;
  }

  .f-logo img {
    height: 68px !important;
  }

  .desktop_none {
    margin-top: 0;
  }

  .creation_banner {
    height: auto;
    overflow: hidden;
    text-align: center;
  }

  .creation_banner .cmn_btn {
    font-size: 11px;
  }

  .advance_search {
    height: auto;
    right: 0;
    position: relative;
    /*width: 100%;*/
    text-align: center;
    display: flex !important;
    top: 0;
    width: 195px;
  }

  .main-post_box {
    text-align: center;
  }

  .trend-sec-box {
    text-align: center;
    width: 100%;
    display: block;
  }

  .trend-title {
    font-size: 18px;
    line-height: 22px;
  }

  .trend-tags {
    display: block;
    text-align: center;
  }

  .welcome_section_popup h2 {
    font-size: 20px;
  }

  .welcome_logo img {
    width: 135px;
  }

  .wecome_categori_border {
    margin-top: 0px;
  }

  .dance_section {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .category_list .welcome_icon img {
    width: 70px;
    margin-top: 20px;
  }

  .wecome_categori_border img {
    width: 120px;
    display: none;
  }

  .category_list .welcome_icon h3 {
    font-size: 16px;
  }

  .book_list_box {
    width: 100%;
    margin: 10px 0;
  }

  #profile.my_canvas_section .col-lg-3 {
    width: 100%;
  }

  .latest_news .col-sm-4 {
    width: 30%;
  }

  .latest_news .col-sm-8 {
    width: 70%;
  }

  .popup_image_section {
    padding: 20px 10px;
  }

  .modal-content {
    position: relative;
  }

  .like_box,
  .comments_Area {
    height: auto;
  }

  .popup_image_section video,
  .popup_image_section audio {
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 0px;
  }

  .popup_image_section {
    height: auto;
  }

  .faq_section {
    margin: 0px 0 0 0;
  }

  .faq_section h3,
  .faq_section h1 {
    font-size: 25px;
  }

  .faq_section h1 {
    margin: 20px 0 30px 0;
  }

  .win-popup_in {
    margin-top: 0;
  }

  .win_list_blog li:nth-child(1),
  .win_list_blog li:nth-child(3) {
    margin-top: 15px;
  }

  .win_popup_heading_in img {
    width: 200px;
  }

  .index-header.home {
    position: relative !important;
    background: #000 !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    left: -10px;
    display: none;
  }

  .new_banner_img {
    width: 205px;
    margin: 0 auto;
  }

  .our_client.new_banner_slider {
    height: auto;
  }

  .carousel-control-prev,
  .carousel-control-next {
    display: block;
  }

  .our_client .carousel-control-prev {
    left: 0;
    right: auto;
  }

  .banner_bottom_bar {
    position: relative;
    bottom: 0;
    margin: 0 auto;
    padding-top: 10px;
    margin-top: 25px;
  }

  .col-xs-5,
  .col-sm-6 {
    width: 50%;
  }

  .banner_bottom_bar {
    width: 80%;
    padding-right: 50px;
    padding-top: 1px;
  }

  .over_text {
    text-align: left;
    margin-top: 20px;
    padding-right: 45px;
  }

  .banner_bottom_bar:before {
    width: 80%;
  }

  .our_client.new_banner_slider .carousel-control-prev,
  .our_client.new_banner_slider .carousel-control-next {
    top: auto;
    height: 60px;
    border: 0px;
    position: absolute;
    bottom: -20px;
  }

  .our_client .carousel-control-prev,
  .our_client .carousel-control-next {
    top: auto;
  }

  .our_client .carousel-control-prev,
  .our_client .carousel-control-next {
    top: auto;
  }

  .Login {
    background-position: top center;
  }

  .login_col_main {
    margin: 15px 10px;
  }

  .container_for_competition_result.container .accordion-header button {
    font-size: 28px;
    line-height: 26px;
  }

  .win_list_blog {
    transform: scale(0.9);
  }

  .container_for_competition_result .win-popup_in .win_list_blog li:nth-child(1),
  .container_for_competition_result .win-popup_in .win_list_blog li:nth-child(3) {
    width: 200px;
    float: none;
    margin: 10px auto;
  }

  .filter_button {
    display: block;
    text-align: right;
  }

  .modal_image_input_1 label {
    font-size: 14px;
    padding: 0;
  }

  .post_action button {
    padding: 2px 10px;
    font-size: 14px;
  }

  .creation_banner .cmn_btn svg,
  .search_left .bg_b svg,
  .post_action .bg_b svg {
    margin-top: 0;
  }

  .post_category_sec select {
    font-size: 14px;
    padding-left: 0;
  }

  .video_section_popup {
    width: 95%;
    height: auto;
  }

  .subscriptionsplan .analytics_list_li {
    width: 250px;
    height: 250px;
  }

  .advance_serch .text_readmore {
    max-height: auto;
  }

  .cover_link {
    bottom: 10px;
    right: 0;
    z-index: 99;
  }

  .cover_link .btn {
    border: none;
    padding: 2px 5px;
    margin: 0 1px !important;
  }

  .profile_inner_photo .img_box {
    height: 100px !important;
    width: 100px !important;
  }

  .profile_outer_photo {
    min-width: 110px;
    min-height: 110px;
    width: 110px;
    height: 110px;
  }

  .profile_outer_photo {
    margin-top: -28px;
  }

  .my_space_links a {
    vertical-align: middle;
  }

  .my_space_links .text-secondary {
    display: none;
  }

  .my_space_links .material-symbols-outlined {
    font-size: 20px;
  }

  .my_space_nav {
    bottom: 50px;
    right: 10px;
    position: absolute;
  }

  .profile_set {
    display: block !important;
    width: 100%;
    height: auto;
    padding-bottom: 13px;
  }

  .profile_set a {
    display: inline-block !important;
  }

  .p_set_navlink {
    padding: 5px 8px !important;
    height: 30px;
  }

  .all_heading {
    font-size: 16px;
  }

  .member_nav_items {
    display: inline-block !important;
  }

  .member_nav_items a {
    display: inline-block !important;
    width: auto !important;
    padding: 2px 5px !important;
    margin-bottom: 8px;
    margin: 0 5px 5px 0;
  }

  .popup_in {
    max-height: 97%;
    width: 97%;
  }
}

@media (min-width: 700px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }

  .organization_post .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 700px) {
  .plan_list li {
    height: auto;
    width: 95%;
    padding-bottom: 30px;
    display: block;
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 600px) {
  .post_img_pro {
    height: 160px;
  }

  .member_nav_items a {
    font-size: 16px;
    padding: 0 0px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    padding-right: 10px;
  }

  .p_set_navlink {
    padding: 10px 10px;
  }

  .about_us_col-6 {
    width: 100%;
  }

  .about_img img,
  .about_img img:nth-child(2) {
    width: 100%;
  }

  .social_details {
    margin-top: 30px !important;
    gap: 15px;
  }

  .sd_tex {
    font-size: 12px;
    letter-spacing: 0px;
  }

  .social_details a,
  .sd_tex {
    font-size: 13px;
  }

  .organiza_follow_us {
    top: -40px;
  }

  .com-sec img {
    height: 80px;
    min-width: 80px;
  }

  .friend_img {
    height: 125px;
  }

  .home_back_img {
    background-size: 50% 100%;
  }

  #features h1 {
    font-size: 30px !important;
  }

  .profile_info_sec {
    padding: 0px;
  }

  .user_profile_info_tags {
    gap: 0px !important;
  }

  .pro_info_tag {
    padding: 10px !important;
    font-size: 14px;
  }

  .opt_more {
    padding: 6px 12px;
    margin-right: 5px;
  }

  .member_box .col-md-4 {
    width: 300px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .banner_bottom_bar {
    width: 100%;
    padding-right: 0px;
    padding-top: 1px;
  }

  .new_banner_img {
    width: 152px;
    margin: 0 auto;
  }

  .banner_bottom_bar::before {
    left: 0px;
  }

  .banner_bottom_bar:before {
    width: 100%;
  }

  .over_text {
    padding-right: 0;
  }

  .new_banner_slider .cmn-new_btn {
    position: relative;
    left: 0;
  }

  .new_about_text {
    margin-top: 0;
  }

  .our_client .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 50px;
    top: 40%;
  }

  .new_about_text {
    padding-bottom: 40px;
  }

  .our_client .carousel-control-prev {
    left: auto;
    right: 72px;
    opacity: 1;
  }

  .client_testimonial {
    margin-top: 25px;
  }

  .cmn-new_btn a,
  button.cmn-new_btn {
    white-space: nowrap;
  }

  .allphanes_provides .new_about_text,
  .new_about_text {
    padding-bottom: 0;
  }

  .videos_new {
    margin-top: 25px;
  }

  .breadcrumb_main {
    width: 300px !important;
  }
}

.post_area_infinite {
  padding-bottom: 10px;
  margin-top: 10px;
  height: 160vh;
  overflow-y: scroll;
}

.h_100v {
  height: 100vh !important;
}

.load_body {
  height: 60vh;
  display: grid;
  place-content: center;
}

.w_max_con {
  min-width: max-content !important;
}

/* extra  */
.todos-list {
  width: 50vw;
  height: 70vh;
  padding: 2rem;
  background-color: rgb(214, 214, 247);
  overflow-y: scroll;
}

.domain_nav {
  /* background-color: rgb(9, 9, 155); */
  color: #000 !important;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 !important;
  background: #e9e9e9;
  font-size: 15px;
  border-bottom: 1px solid #0002;
}

.domain_nav:hover {
  background-color: rgb(180, 180, 182);
}

.domains_body {
  /* background-color: red; */
  margin-top: 0px;
}

.main_Pre pre p {
  font-size: 14px !important;
}

.kk_f p {
  font-weight: 400 !important;
}

.todo {
  border-radius: 3px;
  box-shadow: 0 0.7rem 1rem rgb(93, 93, 93);
  margin-bottom: 1rem;
  background-color: rgb(106, 208, 248);
  min-height: 30%;
  color: blue;
  padding: 1rem;
}

.todo-title {
  font-size: 3rem;
}

/* SPINNER 13 */

.ml-loader {
  position: relative;
  width: 70px;
  height: 70px;
}

.ml-loader div {
  -webkit-transform-origin: 32px 32px;
  -ms-transform-origin: 32px 32px;
  transform-origin: 32px 32px;
  -webkit-animation: 1.2s opaque ease-in-out infinite both;
  animation: 1.2s opaque ease-in-out infinite both;
}

.ds {
  background-color: rgb(219, 213, 213);
  padding: 15px;
}

.ml-loader div::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 30px;
  width: 5px;
  height: 18px;
  border-radius: 10px;
  background-color: rgb(54, 53, 53);
}

.ml-loader div:nth-child(1) {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.ml-loader div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.ml-loader div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ml-loader div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ml-loader div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.ml-loader div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.ml-loader div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.ml-loader div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.ml-loader div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.ml-loader div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.ml-loader div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.ml-loader div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.ml-loader div:nth-child(13) {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.nav-link {
  display: block;
  padding: 0rem 1rem !important;
}

@-webkit-keyframes opaque {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes opaque {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0.1;
  }

  100% {
    opacity: 0.1;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .scrollBar::-webkit-scrollbar {
	display: none;
  }
  
   Hide scrollbar for IE, Edge and Firefox 

  } */

.scrollBar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scrollBar::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.wrapper {
  width: 320px;
  margin: 0 auto;
}

.card-loader {
  background-color: #fff;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08),
    0 -1px 3px 0 rgba(0, 0, 0, 0.06);
  padding: 8px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 0;
  height: 340px;
  overflow: hidden;
}

.card-loader:only-child {
  margin-top: 0;
}

.card-loader:before {
  content: "";
  height: 130px;
  display: block;
  background-color: #ededed;
  box-shadow: -48px 100px 0 -48px #ededed, -51px 140px 0 -51px #ededed,
    -54px 180px 0 -54px #ededed;
}

.card-loader:after {
  content: "";
  background-color: #333;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-name: loader-animate;
  animation-timing-function: linear;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%);
  background: -o-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0) 81%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#00ffffff", GradientType=1);
}

@keyframes loader-animate {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.cl_b {
  color: #000;
}

.notificationBarBottom {
  position: fixed;
  z-index: 101;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  left: calc(25%);
  right: 0;
  background: #ffffff;
  width: 50%;
  color: #000;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  letter-spacing: 0.4px;
  /* box-shadow: 0 0 5px black; */
}

@keyframes slideUp {
  0% {
    transform: translateY(100% + 10px);
  }

  90% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(-30px);
  }
}

.notificationBarBottom {
  animation: slideUp 0.5s ease-in-out forwards;
}

#close {
  display: none;
}

.noti_close {
  /* background-color: red; */
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  background-color: rgb(187, 185, 185);
  color: #fff;
  transition: 0.6s;
}

.noti_close:hover {
  background-color: rgb(136, 136, 136);
  color: #fff;
  transition: 0.6s;
}

.blog_h {
  height: 90vh;
  overflow-y: scroll;
}

.blog_h h1 {
  font-size: 30px;
  font-weight: 600;
}

/* added to show how to hide with a click */
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100% + 10px);
  }
}

.notificationBarBottom.hideMe {
  animation: slideDown 0.5s ease-out forwards;
}

.valid {
  color: #1b651c;
}

.social_login_btn_sec button {
  width: 100% !important;
  border-radius: 5px !important;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #0001;
  border-radius: 1px;
}

.fb_pro_name {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.cl_s {
  color: #00f;
}

.ll_2 {
  font-size: 13px;
  font-weight: 600;
}

.ll_2 .req {
  color: red;
}

.rd_box {
  /* background-color: red; */
  padding: 10px 20px;
  border-radius: 10px;
  color: gray;
}

.rd_box:hover {
  background-color: gainsboro;
}

.rd_box p {
  font-size: 15px;
  margin: 0;
}

.verification_box {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  outline: 1px solid #0002;
}

.verification_box p {
  font-size: 16px;
  margin: 0;
}

.verification_box h1 {
  font-size: 20px;
  font-weight: 550;
}

.loader_text {
  position: relative;
}

.waiting_msg {
  position: absolute;
  background-color: #fff;
  top: -44px;
  padding: 9px;
  right: 0;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  width: max-content;
  font-size: 14px;
}

/* Create Posts */
#createPosts {
  width: 100%;
  height: auto;
  background-color: #fff;
}

#createPosts select {
  width: 100%;
  height: 100%;
  border: 1px solid #0001;
  background-color: #fff;
  padding: 10px;
}

.cpost_body {
  display: flex;
}

.cpost_profile_section {
  padding: 20px;
}

#createPosts .sub-profile-pic {
  height: 71px !important;
  width: 71px !important;
  border-radius: 50%;
}

#createPosts .inputs {
  padding: 10px;
  width: 100%;
  background-color: hsla(0, 0%, 100%);
  margin: 0 0 0 0;
  resize: none;
  border-radius: 0px;
  border: 0;
  background: #f3f3f3;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  outline: none;
}

#createPosts .inputs:focus,
#createPosts .inputs:hover {
  border: 1px solid #0001;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 0px 2px 10px 0px rgba(50, 50, 50, 0.23);
}

.index-header ul li a {
  padding: 0px;
}

.release_date_msg {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -40px;
}

@media only screen and (max-width: 1366px) {
  .video_section_popup {
    width: 95%;
  }

  .index-header ul li a {
    font-size: 16px;
    padding: 0 10px;
  }

  .search_nav {
    padding-right: 5px;
  }

  .member_search {
    width: 190px;
  }

  .nav-link {
    padding: 0 10px !important;
  }

  .cateory_boximg {
    width: 200px;
    height: 165px;
  }
}

@media only screen and (max-width: 1280px) {
  .index-header ul li a {
    font-size: 17px;
  }

  .logo-top img {
    width: 130px;
    height: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .index-header ul li a {
    font-size: 17px;
  }

  .logo-top img {
    width: 120px;
    height: auto;
  }

  .member_search {
    width: 135px;
  }

  .index-header ul {
    padding-left: 10px;
  }

  .index-header ul li a {
    font-size: 16px !important;
    padding: 0 8px !important;
  }

  .catrgory_main li {
    width: 23%;
  }
}

@media only screen and (max-width: 991px) {
  .index-header ul {
    padding-top: 20px;
  }

  .catrgory_main li {
    width: 23%;
  }
}

@media only screen and (max-width: 680px) {
  .catrgory_main li {
    width: 42%;
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 700px) {
  .cpost_body {
    flex-wrap: wrap;
  }

  .cpost_profile_section {
    padding: 0;
    display: grid;
    place-content: center;
    width: 100%;
    padding-top: 20px;
  }

  .m_none {
    display: none;
  }
}

/* End Create Posts */
.cards_load .ldss-spinner div:after {
  background-color: #fff !important;
}

.pr {
  position: relative;
}

.cards_load {
  display: grid;
  place-content: center;
  position: absolute;
  z-index: 999;
  background-color: #0003;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

/* new loader  */
.ldss-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.ldss-spinner div {
  transform-origin: 40px 40px;
  animation: ldss-spinner 1.2s linear infinite;
}

.ldss-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: gray;
}

.ldss-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.ldss-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.ldss-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.ldss-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.ldss-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.ldss-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.ldss-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.ldss-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.ldss-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.ldss-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.ldss-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.ldss-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes ldss-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bg-k {
  background-color: #000;
}

/* end new laoder  */

/* premium list  */
.premium_list {
  display: flex;
  justify-content: start;
}



.premium_card_wrapper {
  padding: 5px;
}

.premium_card_body {
  height: 270px;
  z-index: 2;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  transition: 0.4s;
}

.premium_card_body:hover {
  scale: 1.02;
  transition: 0.4s;
}

.fs_19 {
  font-size: 19px;
}