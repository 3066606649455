#settingRightSec{
    margin-left: 400px;
    min-height: 100vh;
    width: calc(100% - 400px);
}
.setting_left_nav{
    position: fixed;
    width: 400px;
}

#settingsLeftNav{
    height: 100vh;
}

.setting_left ul{
    list-style: none;
}


.setting_left_link{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 10px;
    color: #000;
    font-weight: 500;
    align-items: center;
}

.setting_left_link .icon{
    width: 25px;
    height: 25px;
    margin-right: 15px;
    color: gray;
}

.setting_left_link.active{
    background-color: #e3e7ec;
}

.details_row{
    padding: 15px;
    border-radius: 5px;
    border-bottom: 1px solid #0001;
}
.edit_sec{
    border-top: 1px solid #0001;
    margin-top: 30px;
}

.fw-5{
    font-weight: 600;
}
.input_box{
    display: flex;
}

.input_box label{
    width: 100px;
    margin-top: 10px;
}

.edit_btn_acc{
    cursor: pointer;
    color: rgb(28, 28, 29);
}
.edit_btn_acc:hover{
    text-decoration: underline;
}