.accounts {
    margin-top: -30px;
    width: 100%;
    min-height: calc(100vh + 30px);
    font-family: sans-serif;
    background-color: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    padding-top: 40px;
    display: grid;
    place-content: center;
}

a {
    text-decoration: none;
}

.cl_b {
    color: #000 !important;
}

.flex_center_box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
}

.sub_head {
    width: 16rem;
    margin: 0px 30px;
    /* background-color: red; */
    text-align: center;
    margin-top: 6px;
    color: gray;
    font-size: 12px;
}

.flex_center_box h4, .center_box h4 {
    font-size: 17px;
}

.flex_center_box .box {
    max-width: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffff;
    border: 1px solid #e6e6e6;
    border-radius: 1px;
    margin: 0 0 10px;
    padding: 10px 0;
    flex-grow: 1;
}

.flex_center_box .box p {
    /* margin-top: 10px; */
    font-size: 15px;
}

.head_icon {
    margin: 22px auto 12px;
    background-image: url("https://allphanes.com/assets/web_img/main_logo.png");
    /* background-position: -98px 0; */
    background-size: 100% 100%;
    height: 60px;
    width: 177px;
    overflow: hidden;
}

.flex_center_box .field {
    margin: 10px 0;
    position: relative;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
}

.flex_center_box input {
    padding: 9px 0px 7px 9px;
    font-size: 15px;
    width: 16rem;
    /* height: 1.2rem; */
    outline: none;
    background: #fafafa;
    border-radius: 3px;
    border: 1px solid #efefef;
}

/* label intial position*/

.flex_center_box label {
    position: absolute;
    pointer-events: none;
    left: 10px;
    padding-bottom: 15px;
    transform: translateY(10px);
    line-height: 6px;
    transition: all ease-out 0.1s;
    font-size: 13px;
    color: #999;
    padding-top: 6px;
    font-weight: 600;
}

/* hiding placeholder in all browsers */

.flex_center_box input::placeholder {
    visibility: hidden;
}

/* hiding  placeholder in mozilla */
.flex_center_box .login-form ::-moz-placeholder {
    color: transparent;
}

/* label final position */
.flex_center_box input:not(:placeholder-shown)+label {
    transform: translateY(0);
    font-size: 11px;
}

/* input padding increases if placeholder is not shown */
.flex_center_box input:not(:placeholder-shown) {
    padding-top: 14px;
    padding-bottom: 2px;
}

.login-button {
    text-align: center;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid transparent;
    /* background-color: #3897f0; */
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: grid;
    place-content: center;
}

.center_box{
    display: grid;
    place-content: center;
}

.flex_center_box .separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    margin-top: 6px;
}

.flex_center_box .separator .line {
    height: 1px;
    width: 40%;
    background-color: #dbdbdb;
}

.flex_center_box .separator p {
    font-size: 14px;
    margin: 0;
    font-weight: 550;
}

.flex_center_box .other {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.flex_center_box .fb-login-btn {
    margin: 1rem;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    color: #385185;
    font-weight: 600;
    background: transparent;
}

.flex_center_box .fb-icon {
    color: #385185;
    font-size: 1rem;
    padding-right: 1px;
}

.flex_center_box .forgot-password {
    font-size: 11px;
    color: #003569;
}

.flex_center_box .signup {
    color: #3897f0;
    font-weight: 600;
}