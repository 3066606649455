@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");

/* * {
    font-family: 'Open Sans', sans-serif;
} */
.index-header {
    background-color: #2a2a2a; 
	height: 90px;
	display: flex;
	align-items: center;
	z-index: 999;
	width: 100%;
	position: sticky;
	top: 0;
}

.index-header.home {
	position: absolute;
	top: 0;
	background-color: transparent;
}
.index-header a {
	text-decoration: none !important;
	color: #000;
}
.index-header ul li a {
	color: #fff;
	font-size: 18px;
	padding: 0 5px;
}
.index-header ul li a:hover {
	color: rgb(112, 184, 81);
}
.cl_b{
	color: #000 !important;
}
.cl_1{
	color: #ffffff;
}

.nav-link:hover{
	color: rgb(112, 184, 81) !important;
}
/* .cl_1{

} */
.mt_-90{
	margin-top: -90%;
}
/* .index-header a */

.nav-main,
.nav-right {
	display: flex;
	align-items: center;
	/* gap: 15px; */
}

.nav-main {
	justify-content: space-between;
	width: 100%;
	padding: 0 60px;
}

.nav-middle {
	display: flex;
}

.index-header ul {
	list-style: none;
}

.btn-signs {
	color: #fff !important;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
		#99ca3c;
	border-radius: 20px;
}
.btn-signs:hover {
	color: #fff;
}

.footer-end {
	text-align: center;
	margin-bottom: 20px;
}

.f-links {
	color: #000;
}
.sign-out-btn {
	background-color: rgb(112, 184, 81);
}

.ta_left {
	text-align: left;
}

.f-links:hover {
	color: #000;
	text-decoration: underline;
}

.f-logo {
	height: 100px;
}

#footer {
	padding: 60px 80px;
	background-color: #f5fcff;
}

.social-img {
	width: 180px !important;
	height: 180px;
}

#footer ul li {
	font-size: 18px;
	list-style: none;
	margin-top: 8px;
}

.nav-link.active {
	color: rgb(153 202 60);
}
.f-end {
	margin-bottom: 10px;
}
.f-end a {
	padding: 0 15px;
	border-right: 1px solid #000;
}
.f-end a:last-child {
	border-right: 0px;
}

.desktop_none {
	display: none;
}
.mobile_none {
	display: flex;
}

@media screen and (max-width: 990px) {
	.desktop_none {
		display: block;
	}
	.wi_80{
		right: 0;
	}
	.mobile_none {
		display: none;
	}
	.nav-middle {
		position: absolute;
		padding: 0;
		top: 72px;
		min-height: 100vh;
		flex-direction: column;
		left: -100%;
		/* width: 0; */
		overflow: hidden;
		transition: left 0.6s;
		background-color: #2a2a2a;
		z-index: 10;
	}

	.nav-middle.active {
		left: 0;
		width: 80%;
		transition: left 0.6s;
		border-top: 1px solid #0003;
	}

	.nav-link {
		margin-top: 0;
		border-bottom: 1px solid #fff1;
	}

	.hamburger {
		position: relative;
		width: 50px;
		height: 50px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.h_line {
		margin-top: 7px;
		width: 37px;
		height: 3px;
		background-color: #ffffff;
		transition: 0.6s;
	}

	.h_line:first-child {
		margin-top: 0;
	}

	.hamburger.active .h_line:first-child,
	.hamburger.active .h_line:last-child {
		border-radius: 10px;
		width: 30px;
	}

	.hamburger.active .h_line:first-child {
		transform: rotatez(45deg);
	}

	.hamburger.active .h_line:last-child {
		margin-top: -13px;
		transform: rotatez(-45deg);
	}

	.hamburger.active .h_line:nth-child(2) {
		opacity: 0;
		transition: 0.4s;
	}
}

@media screen and (max-width: 768px) {
	.nav-main {
		padding: 20px 15px;
	}

	.nav-btn {
		padding: 10px !important;
		font-size: 14px;
	}
}

.bg-red {
	background-color: red;
}

/* leftnavbar  */
#sidebar {
	position: fixed;
	top: 0;
	height: 100vh;
	width: auto;
	z-index: 999;
	/* overflow: hidden; */
}

.left-header {
	background-color: red;
}

.ptt-0 {
	padding-top: 0;
}

.pos_rel{
	position: relative;
	/* background-color: blue; */
}

.notification_al{
	background-color: red;
	position: absolute;
	top: 5px;
	right: 20%;
	font-size: 14px;
	height: 17px;
	width: 17px;
	border-radius: 50%;
	display: grid;
	place-content: center;
}